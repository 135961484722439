export const policies_lists = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isBadge: true,
        isFixed: true,
        width: 180
    },
    {
        label: 'Status',
        value: 'status_text',
        width: 200
    },
    {
        label: 'Email<br />Mobile Number',
        value: 'email_mobile',
        isPopover: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Price(VAT)'],
        values: ['quote_ref_no', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount',
        value: 'discrepancy_amount',
        width: 120
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        width: 120
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 250
    },
    {
        label: 'Created On<br />Issued/Completed',
        value: 'created_on',
        width: 180
    },
    {
        label: 'Policy<br />Start Date',
        value: 'policy_start_date',
        width: 150
    },
    {
        label: 'Policy<br />End Date',
        value: 'policy_end_date',
        width: 150
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Car Value<br />(CURRENCY)',
        value: 'car_value',
        width: 120
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: 150
    },
]

export const all_documents = [
    {
        label: 'Preview',
        value: 'file_path',
        isPreview: true,
        width: 100
    },
    {
        label: 'Document Type',
        value: 'title',
        isTextSuccess: true
    },
    {
        label: 'File Name',
        value: 'original_filename',
        isWrap: true,
    },
    {
        label: 'Quote Ref. No.',
        value: 'quote_ref_no',
        width: 220
    },
    {
        label: 'Uploaded By',
        value: 'uploaded_by'
    },
]

export const amended_documents = [
    {
        label: '',
        value: 'file_path',
        isPreview: true,
        width: 100
    },
    {
        label: 'Document Type',
        value: 'title',
        isTextSuccess: true,
        width: 250
    },
    {
        label: 'File Name',
        value: 'original_filename',
        isWrap: true,
        width: 180
    },
    {
        label: 'Amended Quote Ref. No.',
        value: 'quote_ref_no',
        width: 220
    },
    {
        label: 'Uploaded By',
        value: 'uploaded_by'
    },
]

export const document_logs = [
    {
        label: 'Action',
        value: 'action'
    },
    {
        label: 'By',
        value: 'by'
    },
    {
        label: 'At',
        value: 'at'
    },
]

export const email_lists = [
    {
        label: 'Subject',
        value: 'subject',
        width: '450',

    },
    {
        label: 'Sent By',
        value: 'sent_by',
        width: '350'
    },
    {
        label: 'Sent On',
        value: 'created_at',
        width: ''
    },
    {
        label: 'Delivered',
        value: 'delivered',
        width: '',
        isClick: true,
        align: 'center'
    },
    {
        label: 'Clicked',
        value: 'clicked',
        width: '',
        isClick: true,
        align: 'center'
    },
    {
        label: 'View',
        value: 'email_body',
        isView: true,
        width: '150'
    },
]

export const quotes_details = [
    {
        label: 'Car Details',
        value: 'car_details',
        isMarkFlow: true
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: '150'
    },
    {
        label: 'Generate On',
        value: 'generate_on',
        width: '150'
    },
    {
        label: 'Generate By',
        value: 'generate_by',
        isMarkFlow: true,
        width: '150'
    },
    {
        label: 'Companies',
        value: 'companies'
    },
    {
        label: 'Payment Link',
        value: 'payment_link',
        isCopyMarkFlow: true,
    },
]

export const sms = [
    {
        label: 'SMS Content',
        value: 'sms_content'
    },
    {
        label: 'Created on',
        value: 'created_at'
    },
    {
        label: 'Status',
        value: 'status'
    },
]

export const email_activity = [
    {
        label: 'Activity',
        value: 'activity'
    },
    {
        label: 'Sent By',
        value: 'sent_by'
    },
    {
        label: 'Send On',
        value: 'sent_on'
    },
]


export const leads_tasks = [
    {
        label: 'Name',
        value: 'name',
        isRoute: true,
        isWrap: true,
        isLeads: true,
        to: 'customer-details'
    },
    {
        label: 'Email <br />Mobile Number',
        value: 'email_mobile',
        first: 'email',
        second: 'phone_number',
        isCopy: true
    },
    {
        label: 'User Type',
        value: 'customer_status_text',
        width: '320',
        isRoute: true,
        isMixin: true,
        to: 'customer-details'
    },
    {
        label: 'Is Renewal',
        value: 'is_renewal',
        width: '120',
        cn: 'text-center',
        align: 'center'
    },
    {
        label: 'Task #',
        value: 'open_task_count',
        width: '80',
        align: 'center'
    },
    {
        label: 'Task Due Date',
        value: 'task_due_date',
        width: '170',
        isTimeLine: true,
        cni: 'task-due-date'
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent',
        width: '180',
        isRoute: true,
        withCn: true,
        to: 'customer-details'
    },
    {
        label: 'Select to Transfer',
        value: 'customer_id',
        width: '120',
        align: 'center',
        isTransfer: true
    },
]

export const policy_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    {
        label: 'Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 120
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 250
    },
    {
        label: 'Created On<br />Issued/Completed',
        value: 'created_on',
        is_policy_completed: true,
        width: 180
    },
    {
        label: 'Policy<br />Created On',
        value: 'created_on',
        is_policy_assigned: true,
        width: 180
    },
    {
        label: 'Start Date<br />End Date',
        value: 'policy_start_end_date',
        width: 150
    },
    {
        label: 'Refunded On<br />Refunded By',
        value: 'refunded_on_by',
        is_policy_refunded: true,
        width: 200
    },
    {
        label: 'Cancellation Date<br />Cancelled By',
        value: 'cancelled_on_by',
        is_policy_cancelled: true,
        width: 200
    },
    {
        label: 'Approval Date<br />Approved By',
        value: 'approved_on_by',
        is_policy_refunded_cancelled: true,
        width: 200
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Car Value<br />(CURRENCY)',
        value: 'car_value',
        width: 120
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: 150
    },
]

export const policy_cancel_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    {
        label: 'Lead Details',
        value: 'details',
        isWrap: true,
        width: 250
    },
    {
        label: 'Policy Status',
        value: 'status_text',
        width: 250
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Cancellation Type',
        value: 'cancellation_type_text',
        width: 150
    },
    {
        label: 'Cancellation Reason',
        value: 'cancellation_reason_text',
        width: 150
    },
    {
        label: 'Payable<br />Amount (CURRENCY)',
        value: 'cancellation_payable_amount',
        width: 120
    },
    {
        label: 'Credit Note (CURRENCY)',
        value: 'cancellation_credit_amount',
        width: 120
    },
    {
        label: 'CN No.',
        value: 'cancellation_credit_notes_no',
        width: 120
    },
    {
        label: 'Debit Note (CURRENCY)',
        value: 'cancellation_debit_amount',
        width: 120
    },
    {
        label: 'DN No.',
        value: 'cancellation_debit_notes_no',
        width: 120
    },
    {
        label: 'Endorsement No.',
        value: 'cancellation_endorsement_no',
        width: 150
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 120
    },
    {
        label: 'Insurance Payment Type',
        value: 'insurance_payment_text',
        width: 150
    },
    
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Cancellation<br />Requested On',
        value: 'cancellation_request_date',
        width: 150
    },
    
]

export const policy_refund_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        width: 180
    },
    {
        label: 'Lead Details',
        value: 'details',
        isWrap: true,
        width: 250
    },
    {
        label: 'Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Policy Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Policy Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Invoice Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Refund Type',
        value: 'refund_type_text',
        width: 200
    },
    {
        label: 'Refund Reason',
        value: 'refund_reasons_text',
        width: 200
    },
    {
        label: 'Payable Amount<br />(CURRENCY)',
        value: 'refund_payable_amount_formated',
        width: 200
    },
    {
        label: 'Transaction <br />Type',
        value: 'transaction_type_text',
        isPopover: true,
        headers: [''],
        values: ['online_link'],
        width: 120
    },
    {
        label: 'Invoice Amount<br />VAT(CURRENCY)',
        value: 'invoice_amount_break',
        is_policy_completed: true,
        width: 180
    },
    
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    
    {
        label: 'Refund<br />Requested On',
        value: 'refund_created_at',
        width: 200
    },
]


export const invoice_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        isPopover: true,
        headers: ['RELATION Quote Ref. No.'],
        values: ['relation_quote_ref_no'],
        width: 280
    },
    {
        label: 'INVOICE_TYPE Status',
        value: 'status_text',
        width: 150
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
    {
        label: 'Reference No.',
        value: 'reference_no',
        width: 170
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: 170
    },
    {
        label: 'Invoice Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Invoice Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Policy Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Policy Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Discrepancy <br />Status',
        value: 'discrepancy_status',
        is_discrepancy: true,
        width: 160
    },
    {
        label: 'Transaction <br />Type / Code',
        value: 'transaction_type_text',
        isPopover: true,
        copyValue: true,
        headers: [''],
        values: ['payment_link'],
        width: 200
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Approved By &<br />Approved On',
        value: 'approved_by_on',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
]

export const invoice_for_approval_header = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed: true,
        isWrap: true,
        isPopover: true,
        headers: ['RELATION Quote Ref. No.'],
        values: ['relation_quote_ref_no'],
        width: 250
    },
    {
        label: 'INVOICE_TYPE Status',
        value_1: 'status',
        value_2: 'status_text',
        hasOptions: true,
        width: 250
    },
    {
        label: 'Email',
        value: 'email',
        isPopover: true,
        copyValue: true,
        headers: ['Customer Name'],
        values: ['customer_name'],
        width: 250
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
    {
        label: 'Reference No.',
        value: 'reference_no',
        width: 170
    },
    {
        label: 'Payment Date',
        value: 'payment_date',
        width: 170
    },
    {
        label: 'Invoice Price &amp;<br />VAT (CURRENCY)',
        label_without_vat: 'Invoice Price (CURRENCY)',
        isVatCheck: true,
        value: 'price_vat',
        value_without_vat: 'price',
        isPopover: true,
        headerTitle: 'Price Details',
        headers: ['Quote Ref No.', 'Policy Price(VAT)'],
        values: ['quote_ref_no_amend', 'price_vat_inline'],
        headers_without_vat: ['Quote Ref No.', 'Policy Price'],
        values_without_vat: ['quote_ref_no_amend', 'price_inline'],
        withAmend: true,
        amend: 'amend_invoices',
        amend_without_vat: 'amend_invoices_without_vat',
        width: 150
    },
    {
        label: 'Discrepancy <br />Amount',
        value: 'discrepancy_amount',
        width: 160
    },
    {
        label: 'Transaction <br />Type / Code',
        value: 'transaction_type_text',
        isPopover: true,
        copyValue: true,
        headers: [''],
        values: ['payment_link'],
        width: 200
    },
    {
        label: 'Underwritter &<br />Policy SA',
        value: 'upsa',
        width: 200
    },
    {
        label: 'Invoice By &<br />Created On',
        value: 'ibco',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 120
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
]

export const promocode_listing_header = [
    {
        label: 'Promo Code',
        value: 'promotion_code',
        isFixed:true,
        width: 180
    },
    {
        label: 'Campaign',
        value: 'campaign_name',
        width: 180,
        isFixed: true,
    },
    {
        label: 'Discount',
        value: 'code_value_new_text',
        width: 150
    },
    {
        label: 'Discount Type',
        value: 'discount_type_text',
        width: 150
    },
    {
        label: 'Insurance Type',
        value: 'insurance_type_text',
        width: 150
    },
    {
        label: 'Policy Type',
        value: 'policy_type_text',
        width: 150
    },
    {
        label: 'Start Date',
        value: 'start_date_format',
        width: 120
    },
    {
        label: 'Expiry Date',
        value: 'expiry_date_format',
        width: 120
    },
    // {
    //     label: 'Usage',
    //     value: 'usage_type_text',
    //     width: 100
    // },
    // {
    //     label: 'Status',
    //     value: 'status_text',
    //     width: 120
    // },
    {
        label: 'Created On',
        value: 'created_at',
        width: 150
    }



]

export const quote_request_documents = [
    {
        label: 'Select Quote',
        value: 'id',
        isFixed:true,
        width: 180
    },
    {
        label: 'Preview',
        value: 'customer_id',
        width: 180,
        isFixed: true,
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 150
    },
    // {
    //     label: 'Companies',
    //     value: 'discount_type_text',
    //     width: 150
    // },
    // {
    //     label: 'File Name',
    //     value: 'policy_type_text',
    //     width: 150
    // },
    // {
    //     label: 'Uploaded On <br> Uploaded By',
    //     value: 'start_date_format',
    //     width: 120
    // }
]

export const marketing_campaign_listing_headers = [
    {
        label: 'Campaign ID',
        value: 'id',
        isFixed:false,
        width: 180
    },
    {
        label: 'Campaign Name',
        value: 'name',
        isFixed:false,
        width: 400
    },
    {
        label: 'Created By',
        value: 'created_by_user_name',
        isFixed:false,
        width: 400
    },
    {
        label: 'Total Spent',
        value: 'total_spent',
        isFixed:false,
        width: 400
    }
]

export const marketing_report_headers = [
    {
        label: 'Campaign Name',
        value: 'campaignName',
        isFixed:true,
        width: 400,
    },
    {
        label: 'UTM Source',
        value: 'utmSource',
        isFixed:false,
        width: 180
    },
    {
        label: 'UTM Medium',
        value: 'utmMedium',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Leads',
        value: 'totalLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Deals',
        value: 'totalDeals',
        isFixed:false,
        width: 150
    },
    {
        label: 'Conversion <br> Ratio (%)',
        value: 'totalDealsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['totalDealsRatio', 'totalDealsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'Amount Spent',
        value: 'amountSpent',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost Per Lead',
        value: 'costPerLead',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost of Leads',
        value: 'costOfLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Cost of Deals',
        value: 'costOfDeals',
        isFixed:false,
        width: 150
    },
    {
        label: 'Total Revenue',
        value: 'totalRevenue',
        isFixed:false,
        width: 150
    },
    {
        label: 'Credit Note',
        value: 'creditNotes',
        isFixed:false,
        width: 150
    },
    {
        label: 'Profitability',
        value: 'profitability',
        isFixed:false,
        width: 150
    },
    {
        label: 'ROAS',
        value: 'roas',
        isFixed:false,
        width: 150
    },
    {
        label: 'Avg. Policy <br> Price',
        value: 'avgPolicyPrice',
        isFixed:false,
        width: 150
    },
    {
        label: 'Count of Comp <br> Sold',
        value: 'comprehensiveSold',
        isFixed:false,
        width: 150
    },
    {
        label: 'Count of TPL <br> Sold',
        value: 'tplSold',
        isFixed:false,
        width: 150
    },
    {
        label: 'No of Lost <br> Leads',
        value: 'lostLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Lost Leads <br> Ratio (%)',
        value: 'lostLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['lostLeadsRatio', 'lostLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of WIP <br> Leads (NL/PL)',
        value: 'wipLeads',
        isFixed:false,
        isPopover:true,
        headerTitle: 'WIP Leads',
        headers: ['New Leads', 'Pending Leads'],
        values: ['newLeads', 'pendingLeads'],
        width: 170
    },
    {
        label: 'WIP Leads <br> Ratio (%)',
        value: 'wipLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['wipLeadsRatio', 'wipLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of New <br> Leads (NL/QL)',
        value: 'newLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'New Leads <br> Ratio (%)',
        value: 'newLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['newLeadsRatio', 'newLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of Pending <br> Leads',
        value: 'pendingLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Pending Leads <br> Ratio (%)',
        value: 'pendingLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['pendingLeadsRatio', 'pendingLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    },
    {
        label: 'No of Deleted <br> Leads',
        value: 'deletedLeads',
        isFixed:false,
        width: 150
    },
    {
        label: 'Deleted Leads <br> Ratio (%)',
        value: 'deletedLeadsRatioWithAverage',
        isFixed:false,
        isPopover:true,
        headerTitle: 'Conversion',
        headers: ['Ratio', 'Average'],
        values: ['deletedLeadsRatio', 'deletedLeadsRatioAvg'],
        width: 150,
        isTotalCheck: true
    }
]


export const policies_assigned_vs_completed = [
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:true,
        width: 200
    },
    {
        label: 'Quote Reference No',
        value: 'quote_ref_no',
        isFixed:true,
        width: 220
    },
    {
        label: 'Policy Status',
        value: 'status_text',
        width: 180
    },
    {
        label: 'Underwriter',
        value: 'underwriter_name',
        width: 150
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent_name',
        width: 150
    },
    {
        label: 'Transaction Type',
        value: 'transaction_type_text',
        width: 120
    },
    {
        label: 'Bank Transaction Code (For Online)',
        value: 'bank_transaction_code',
        width: 200
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Policy Type',
        value: 'policy_type_text',
        width: 150
    },
    {
        label: 'Policy No.',
        value: 'policy_number',
        width: 180
    },
    {
        label: 'Policy Price',
        value: 'policy_price',
        width: 120
    },
    {
        label: 'VAT',
        value: 'policy_price_vat',
        width: 100
    },
    {
        label: 'Policy Start Date',
        value: 'policy_start_date_format',
        width: 150
    },
    {
        label: 'Policy Expiry Date',
        value: 'policy_end_date_format',
        width: 150
    },
    {
        label: 'Vehicle Details',
        value: 'vehicle_details',
        width: 300
    },
    {
        label: 'Vehicle Value',
        value: 'vehicle_value',
        width: 150
    },
]

export const agent_sales = [
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:true,
        width: 200
    },
    {
        label: 'Quote<br />Reference No',
        value: 'merchant_reference',
        isFixed:true,
        width: 220
    },
    {
        label: 'Invoice<br/>Created By',
        value: 'created_by_name',
        width: 150
    },
    {
        label: 'Policy <br />Created On',
        value: 'policy_created_on',
        width: 180
    },
    {
        label: 'Policy <br />Completed Date',
        value: 'policy_completed_on',
        width: 180
    },
    {
        label: 'Policy Price',
        value: 'original_price',
        width: 100
    },
    {
        label: 'Policy Price (VAT)',
        value: 'original_price_vat',
        width: 150
    },
    {
        label: 'Sale Price /<br/>Invoice Amount',
        value: 'sale_price',
        width: 180
    },
    {
        label: 'Sale Price (VAT)',
        value: 'sale_price_vat',
        width: 100
    },
    {
        label: 'Discount Amount',
        value: 'discount_amount',
        width: 100
    },
    {
        label: 'Discount Code',
        value: 'promo_code',
        width: 100
    },
    {
        label: 'Transaction Type',
        value: 'transaction_type',
        width: 120
    },
    {
        label: 'Policy<br/>Sales Agent',
        value: 'policy_sales_agent_name',
        width: 150
    },
    {
        label: 'Policy Status',
        value: 'policy_purchase',
        width: 150
    },
    {
        label: 'Debit Note',
        value: 'debit_note',
        width: 100
    },
    {
        label: 'Debit <br/>Note (VAT)',
        value: 'debit_note_vat',
        width: 100
    },
    {
        label: 'Cancellation <br />Completed On',
        value: 'cancellation_completed_date',
        width: 220
    },
    {
        label: 'Cancellation Amount',
        value: 'cancellation_payable_amount',
        width: 150
    },
    {
        label: 'Cancellation <br />Amount (VAT)',
        value: 'cancellation_payable_amount_vat',
        width: 150
    },
    {
        label: 'Cancellation <br />Debit Note',
        value: 'cancellation_debit_amount',
        width: 150
    },
    {
        label: 'Cancellation <br />Credit Note',
        value: 'cancellation_credit_amount',
        width: 150
    },
    {
        label: 'Refund <br />Completed On',
        value: 'refunded_on',
        width: 220
    },
    {
        label: 'Total <br />Refund Amount',
        value: 'total_refund_amount',
        width: 180,
        isPopover: true,
        headerTitle: 'Total Payable Refund',
        headers: ['Customer', 'Agent'],
        values: ['total_refund_customer', 'total_refund_agent'],
    },
    {
        label: 'Refund<br/>Amount (VAT)',
        value: 'total_refund_amount_vat',
        width: 180
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 180
    },
    {
        label: 'Policy No.',
        value: 'policy_no',
        width: 180
    },
    {
        label: 'Policy<br/> Start Date',
        value: 'policy_start_date',
        width: 180
    },
    {
        label: 'Policy<br/> End Date',
        value: 'policy_end_date',
        width: 180
    },
    {
        label: 'Vehicle Details',
        value: 'vehicle_details',
        width: 300
    },
    {
        label: 'Vehicle Value',
        value: 'vehicle_value',
        width: 150
    },
    {
        label: 'Underwriter',
        value: 'policy_underwriter_name',
        width: 150
    },
]

export const admin_report = [
    {  value: 'name', label: 'Months-Year' },
    {  value: "total_sale", label: "Total Sales" },
    {  value: "total_leads", label: "Total Leads" },
    {  value: "total_closing_ratio", label: "Closing Ratio%" },
    {  value: "total_comp", label: "Comp" },
    {  value: "total_tpl", label: "TPL" },
    {  value: "total_revenue", label: "Total Revenue" },
    {  value: "new_sale", label: "New Sales" },
    {  value: "new_leads", label: "New Leads" },
    {  value: "new_closing_ratio", label: "Closing Ratio%" },
    {  value: "new_comp", label: "Comp" },
    {  value: "new_tpl", label: "TPL" },
    {  value: "new_revenue", label: "Revenue" },
    {  value: "wip_sale", label: "WIP Sales" },
    {  value: "wip_comp", label: "Comp" },
    {  value: "wip_tpl", label: "TPL" },
    {  value: "wip_revenue", label: "Revenue" },
    {  value: "renewal_sale", label: "Renewal Sales" },
    {  value: "renewal_leads", label: "Renewal Leads" },
    {  value: "renewal_closing_ratio", label: "Closing Ratio%" },
    {  value: "renewal_comp", label: "Comp" },
    {  value: "renewal_tpl", label: "TPL" },
    {  value: "renewal_revenue", label: "Revenue" },
    {  value: "llr_sale", label: "Lost Lead Renewal Sales" },
    {  value: "llr_leads", label: "Lost Lead Renewals" },
    {  value: "llr_closing_ratio", label: "Closing Ratio%" },
    {  value: "llr_comp", label: "Comp" },
    {  value: "llr_tpl", label: "TPL" },
    {  value: "llr_revenue", label: "Revenue" },
    {  value: "lost_leads", label: "Lost Leads" },
    {  value: "lrl_leads", label: "Lead Renewal Lost" },
    {  value: "hot_lead", label: "HL" },
    {  value: "return_hot_lead", label: "RHL" },
    {  value: "work_in_progress", label: "WIP" },
    {  value: "ltr_sale", label: "Current Year Renewal Sales" },
    {  value: "ltr_leads", label: "Previous Year Renewal Leads" },
    {  value: "ltr_closing_ratio", label: "Closing Ratio%" },
    {  value: "ltr_comp", label: "Comp" },
    {  value: "ltr_tpl", label: "TPL" },
    {  value: "ltr_revenue", label: "Revenue" },
    {  value: "untouched_customers", label: "Untouched Customers" },
    {  value: "untouched_renewal_leads", label: "Untouched Renewal Leads" },
    {  value: "untouched_new_leads", label: "Untouched New Leads" },
    {  value: "untouched_llr", label: "Untouched LLR" },
    {  value: "total_refunds", label: "Total Refund" },
    {  value: "commission_paid_to_agent", label: "Commission Paid To Agent" },
    {  value: "partial_refund", label: "Partial Refund" },
    {  value: "full_refund", label: "Full Refund" },
    {  value: "total_refund_amount", label: "Total Refund Amount" },
    {  value: "partial_refund_amount", label: "Partial Refund Amount" },
    {  value: "full_refund_amount", label: "Full Refund Amount" },
    {  value: "total_cancellation", label: "Total Cancellation" },
    {  value: "partial_cancellation", label: "Partial Cancellation" },
    {  value: "full_cancellation", label: "Full Cancellation" },
    {  value: "total_cancellation_amount", label: "Total Cancellation Amount" },
    {  value: "partial_cancellation_amount", label: "Partial Cancellation Amount" },
    {  value: "full_cancellation_amount", label: "Full Cancellation Amount" },
    {  value: "total_revenue_inc_addons", label: "Total Revenue (inc Addons)" },
    {  value: "addons", label: "Addons" }
]

export const approved_invoices = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed:true,
        width: 250
    },
    {
        label: 'Invoice Type',
        value: 'invoice_type',
        width: 150
    },
    {
        label: 'Invoice Price (CURRENCY)',
        value: 'invoice_price',
        width: 220
    },
    {
        label: 'Invoice Price VAT (CURRENCY)',
        value: 'invoice_price_vat',
        width: 220
    },
    {
        label: 'Policy Price (CURRENCY)',
        value: 'policy_price',
        width: 220
    },
    {
        label: 'Policy Price VAT (CURRENCY)',
        value: 'policy_price_vat',
        width: 220
    },
    {
        label: 'Discrepancy <br />Amount',
        value: 'discrepancy_amount',
        width: 220
    },
    {
        label: 'Invoice Status',
        value: 'status_text',
        width: 200
    },
    {
        label: 'Transaction Type',
        value: 'payment_type_text',
        width: 200
    },
    {
        label: 'Bank Transaction Code (For Online)',
        value: 'payment_type_online',
        width: 220
    },
    {
        label: 'Underwriter',
        value: 'policy_underwriter_name',
        width: 200
    },
    {
        label: 'Policy SA',
        value: 'policy_sales_agent_name',
        width: 200
    },
    {
        label: 'Created On',
        value: 'created_at',
        width: 200
    },
    {
        label: 'Invoice By',
        value: 'created_by_name',
        width: 200
    },
    {
        label: 'Invoice Approved On',
        value: 'invoice_approved_at',
        width: 200
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 200
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 180
    },
    {
        label: 'Order Description',
        value: 'order_description',
        width: 200
    },
]

export const account_masters = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        isFixed:true,
        width: 250
    },
    {
        label: 'Invoice Approved On',
        value: 'quote_ref_no',
        isFixed:true,
        width: 250
    },
    {
        label: 'Transaction <br/> Type',
        value: 'transaction_type',
        width: 120
    },
    {
        label: 'Reference <br/> Number',
        value: 'reference_no',
        width: 150
    },
    {
        label: 'Payment <br/> Date',
        value: 'payment_date',
        width: 150
    },
    {
        label: 'Transaction <br/> Code',
        value: 'transaction_code',
        width: 150
    },
    {
        label: 'Customer <br/> Name',
        value: 'customer_name',
        width: 220
    },
    {
        label: 'Policy <br/> Created On',
        value: 'policy_purchase_at',
        width: 150
    },
    {
        label: 'Policy <br/> Completed On',
        value: 'policy_purchase_issue_at',
        width: 150
    },
    {
        label: 'Policy <br/> Status',
        value: 'policy_status_text',
        width: 120
    },
    {
        label: 'Insurance <br/> Payment Type',
        value: 'insurance_payment_text',
        width: 220
    },
    {
        label: 'Policy <br/> Start Date',
        value: 'policy_start_date_format',
        width: 150
    },
    {
        label: 'Policy <br/> End Date',
        value: 'policy_end_date_format',
        width: 150
    },
    {
        label: 'Underwriter',
        value: 'policy_underwriter_name',
        width: 150
    },
    {
        label: 'Policy <br/> Number',
        value: 'policy_number',
        width: 180
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Company Name',
        value: 'company_name',
        width: 220
    },
    {
        label: 'Policy Type',
        value: 'policy_type_text',
        width: 180
    },
    {
        label: 'Policy <br/> Price (CURRENCY)',
        value: 'policy_price',
        width: 180
    },
    {
        label: 'Policy Price<br/> VAT (CURRENCY)',
        value: 'policy_price_vat',
        width: 180
    },
    {
        label: 'Sale Price<br/> (CURRENCY)',
        value: 'sale_price',
        width: 180
    },
    {
        label: 'Sale Price<br/> VAT (CURRENCY)',
        value: 'sale_price_vat',
        width: 180
    },
    {
        label: 'Discount (CURRENCY)<br/>  by SA',
        value: 'discount_amount',
        width: 200
    },
    {
        label: 'Discount Code',
        value: 'promotion_code',
        width: 120
    },
    {
        label: 'Discount Amount for Code entered  (CURRENCY)',
        value: 'discount_amount_code_value',
        width: 200
    },
    {
        label: 'Credit<br/> Note (CURRENCY)',
        value: 'credit_amount',
        width: 120
    },
    {
        label: 'Credit Note<br/> VAT (CURRENCY)',
        value: 'credit_amount_vat',
        width: 120
    },
    {
        label: 'Debit<br/> Note (CURRENCY)',
        value: 'debit_amount',
        width: 120
    },
    {
        label: 'Debit Note<br/> VAT (CURRENCY)',
        value: 'debit_amount_vat',
        width: 120
    },
    {
        label: 'Discrepancy <br/> Amount (CURRENCY)',
        value: 'discrepancy_amount',
        width: 150
    },
    {
        label: 'Amendment <br/> Type',
        value: 'amend_type_text',
        width: 220
    },
    {
        label: 'Amendment <br/> Amount (CURRENCY)',
        value: 'amendment_amount',
        width: 150
    },
    {
        label: 'Cancellation <br/> Type',
        value: 'cancellation_type_text',
        width: 220
    },
    {
        label: 'Cancellation <br/> Amount (CURRENCY)',
        value: 'cancellation_amount',
        width: 150
    },
    {
        label: 'Cancellation Credit <br/> Note (CURRENCY)',
        value: 'cancellation_credit_amount',
        width: 180
    },
    {
        label: 'Cancellation Credit <br/> Note VAT (CURRENCY)',
        value: 'cancellation_credit_amount_vat',
        width: 180
    },
    {
        label: 'Cancellation Debit <br/> Note (CURRENCY)',
        value: 'cancellation_debit_amount',
        width: 180
    },
    {
        label: 'Cancellation Debit <br/> Note VAT (CURRENCY)',
        value: 'cancellation_debit_amount_vat',
        width: 200
    },
    {
        label: 'Cancellation <br/> Reason',
        value: 'cancellation_debit_amount_vat',
        width: 220
    },
    {
        label: 'Agent <br/> Cancellation Notes',
        value: 'cancellation_note',
        width: 220
    },
    {
        label: 'Underwriter <br/> Cancellation Notes',
        value: 'underwriter_cancellation_notes',
        width: 220
    },
    {
        label: 'Cancellation <br/> Requested On',
        value: 'cancellation_request_date',
        width: 150
    },
    {
        label: 'Cancellation <br/> Completed On',
        value: 'cancellation_completed_date',
        width: 150
    },
    {
        label: 'Cancellation <br/> Completed By',
        value: 'cancellation_completed_by_name',
        width: 180
    },
    {
        label: 'Refund Type',
        value: 'refund_type_text',
        width: 220
    },
    {
        label: 'Refund <br/> Amount (CURRENCY)',
        value: 'refund_amount',
        width: 220
    },
    {
        label: 'Refund Reason',
        value: 'refund_reason',
        width: 220
    },
    {
        label: 'Agent <br/> Refund Notes',
        value: 'agent_refund_notes',
        width: 220
    },
    {
        label: 'Refund <br/> Requested On',
        value: 'refund_created_at',
        width: 150
    },
    {
        label: 'Refund <br/> Completed On',
        value: 'refunded_on',
        width: 150
    },
    {
        label: 'Refund <br/> Completed By',
        value: 'refunded_by',
        width: 150
    },
    {
        inLoopHeader: true,
        key: 'car_refunds',
        headers: [
            {
                label: 'NUMBER_TYPE Refund <br/> Type',
                value: 'refund_type_text',
                width: 150
            },
            {
                label: 'NUMBER_TYPE Refund <br/> Amount (CURRENCY)',
                value: 'refund_type_amount',
                width: 150
            },
            {
                label: 'NUMBER_TYPE Refund <br/> Reason',
                value: 'refund_reasons_text',
                width: 220
            },
            {
                label: 'NUMBER_TYPE Agent <br/> Refund Notes',
                value: 'refund_note',
                width: 220
            },
            {
                label: 'NUMBER_TYPE Refund <br/> Requested On',
                value: 'created_at',
                width: 220
            },
            {
                label: 'NUMBER_TYPE Refund <br/> Completed On',
                value: 'refunded_on',
                width: 150
            },
            {
                label: 'NUMBER_TYPE Refund <br/> Completed By',
                value: 'refunded_by_name',
                width: 150
            },
        ]
    },
    {
        label: 'Vehicle Year',
        value: 'car_year',
        width: 150
    },
    {
        label: 'Vehicle <br /> Manufacturer',
        value: 'car_make',
        width: 220
    },
    {
        label: 'Vehicle <br /> Model',
        value: 'car_model',
        width: 220
    },
    {
        label: 'Vehicle Trim',
        value: 'model_details',
        width: 220
    },
    {
        label: 'Date of <br /> Registration',
        value: 'first_registration_date_format',
        width: 150
    },
    {
        label: 'Vehicle <br /> Value',
        value: 'car_value',
        width: 220
    },
    {
        label: 'Registered <br /> in Emirates',
        value: 'registration_emirate_text',
        width: 180
    },
    {
        label: 'Lead Source',
        value: 'lead_source',
        width: 150
    },
    {
        label: 'Agent',
        value: 'policy_sales_agent_name',
        width: 150
    },
]

export const discrepancy = [
    {
        label: 'Created On',
        value: 'created_at',
        isFixed:true,
        width: 200
    },
    {
        label: 'Quote Reference No.',
        value: 'merchant_reference',
        isFixed:true,
        width: 220
    },
    {
        label: 'Invoice<br />Created By',
        value: 'created_by_name',
        width: 150
    },
    {
        label: 'Policy Price',
        value: 'original_price',
        width: 100
    },
    {
        label: 'Policy Price (VAT)',
        value: 'original_price_vat',
        width: 100
    },
    {
        label: 'Sale Price / Invoice Amount',
        value: 'sale_price',
        width: 100
    },
    {
        label: 'Sale Price (VAT)',
        value: 'sale_price_vat',
        width: 100
    },
    {
        label: 'Discount Amount',
        value: 'discount_amount',
        width: 100
    },
    {
        label: 'Discount Code',
        value: 'promotion_code',
        width: 100
    },
    {
        label: 'Transaction Type',
        value: 'payment_type_text',
        width: 120
    },
    {
        label: 'Policy<br />Sales Agent',
        value: 'policy_sales_agent_name',
        width: 150
    },
    {
        label: 'Underwriter',
        value: 'policy_underwriter_name',
        width: 150
    },
    {
        label: 'Discrepancy Amount',
        value: 'discrepancy_amount',
        width: 120
    },
    {
        label: 'Discrepancy Status',
        value: 'discrepancy_status',
        width: 150
    },
    {
        label: 'Discrepancy<br />Approved By',
        value: 'discrepany_approved_by_name',
        width: 150
    },
    {
        label: 'Discrepancy<br />Approved On',
        value: 'discrepancy_approved_at',
        width: 150
    },
    {
        label: 'Credit Note',
        value: 'credit_amount',
        width: 150
    },
    {
        label: 'Credit Note VAT',
        value: 'credit_amount_vat',
        width: 150
    },
    {
        label: 'Debit Note',
        value: 'debit_amount',
        width: 150
    },
    {
        label: 'Debit Note VAT',
        value: 'debit_amount_vat',
        width: 150
    },
    {
        label: 'Policy Status',
        value: 'policy_purchase_text',
        width: 150
    },
    {
        label: 'Policy Name',
        value: 'policy_name',
        width: 220
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 220
    },
    {
        label: 'Policy No.',
        value: 'policy_number',
        width: 180
    },
    {
        label: 'Policy<br />Start Date',
        value: 'policy_start_date_format',
        width: 180
    },
    {
        label: 'Policy<br />End Date',
        value: 'policy_end_date_format',
        width: 180
    },
]

export const dashboard_details = [
    {
        label: 'Customer Id',
        value: 'customer_id',
        isFixed:true,
        width: 120
    },
    {
        label: 'Name',
        value: 'customer_name',
        isFixed:true,
        width: 220
    },
    {
        label: 'Email',
        value: 'customer_email',
        isFixed:true,
        width: 220
    },
    {
        label: 'Mobile',
        value: 'customer_phone',
        width: 150
    },
    {
        label: 'Lead Source',
        value: 'lead_source',
        width: 150
    },
    {
        label: 'Lead Score',
        value: 'lead_score',
        width: 150
    },
    {
        label: 'Lead Status',
        value: 'lead_status',
        width: 150
    },
    {
        label: 'Sales Agent',
        value: 'sales_agent',
        width: 180
    },
    {
        label: 'Other Lead Source',
        value: 'other_lead_source',
        width: 300
    },
    {
        label: 'UTM Source',
        value: 'utm_source',
        width: 150
    },
    {
        label: 'UTM Medium',
        value: 'utm_medium',
        width: 150
    },
    {
        label: 'Other UTM Medium',
        value: 'other_utm_medium',
        width: 300
    },
    {
        label: 'UTM Campaign',
        value: 'utm_campaign',
        width: 150
    },
    {
        label: 'Other UTM Campaign',
        value: 'other_utm_campaign',
        width: 300
    },
    {
        label: 'UTM Content',
        value: 'utm_content',
        width: 150
    },
    {
        label: 'Other UTM Content',
        value: 'other_utm_content',
        width: 300
    },
    {
        label: 'Lead ID',
        value: 'lead_id',
        width: 120
    },
    {
        label: 'Vehicle Year',
        value: 'vehicle_year',
        width: 150
    },
    {
        label: 'Vehicle Manufacturer',
        value: 'car_make',
        width: 150
    },
    {
        label: 'Vehicle Model',
        value: 'car_model',
        width: 150
    },
    {
        label: 'Vehicle Trim',
        value: 'car_trim',
        width: 150
    },
    {
        label: 'Policy Type',
        value: 'policy_type',
        width: 150
    },
    {
        label: 'Policy Price',
        value: 'policy_price',
        width: 150
    },
    {
        label: 'Created On',
        value: 'created_on',
        width: 150
    },
    {
        label: 'Updated On',
        value: 'updated_on',
        width: 180
    },
]

export const login_info = [
    {
        label: 'Login username',
        value: 'user_name',
        isFixed:true,
        width: 150
    },
    {
        label: 'Login Location',
        value: 'location',
        isFixed:true,
        width: 220
    },
    {
        label: 'Type of Machine',
        value: 'type_of_machine',
        width: 150
    },
    {
        label: 'IP',
        value: 'ip',
        width: 180
    },
    {
        label: 'Browser Types',
        value: 'browser_type',
        width: 180
    },
    {
        label: 'Login Date Time',
        value: 'login_date_time',
        width: 150
    },
    {
        label: 'Logout Date Time',
        value: 'logout_date_time',
        width: 150
    },
    {
        label: 'Type of Logout',
        value: 'type_of_logout',
        width: 150
    },
    {
        label: 'IsActive Session',
        value: 'is_active',
        width: 150
    },
]

export const closed_leads = [
    {
        label: 'Lead<br/>Created On',
        value: 'leads_created_at',
        isFixed:true,
        width: 180
    },
    {
        label: 'Lead<br/>Assigned To',
        value: 'leads_assigned_to',
        width: 180
    },
    {
        label: 'Lead Status<br/>1st Year',
        value: 'lead_first_year_status',
        width: 180
    },
    {
        label: 'Year 1<br/>Sales Agent',
        value: 'lead_first_year_agent',
        width: 180
    },
    {
        label: 'Lead Status<br/>2nd Year',
        value: 'lead_second_year_status',
        width: 180
    },
    {
        label: 'Year 2<br/>Sales Agent',
        value: 'lead_second_year_agent',
        width: 180
    },
    {
        label: 'Lead<br/>Closed On',
        value: 'lead_closed_lead',
        width: 180
    },
    {
        label: 'Lead<br/>Closed By',
        value: 'lead_closed_lead_by',
        width: 180
    },
    {
        label: 'Lead Sources',
        value: 'lead_sources',
        width: 180
    },
    {
        label: 'Customer Name',
        value: 'customer_name',
        width: 180
    },
    {
        label: 'Email',
        value: 'customer_email',
        width: 180
    },
    {
        label: 'Phone Number',
        value: 'customer_phone_number',
        width: 180
    },
    {
        label: 'Vehicle Details',
        value: 'car_detail_name',
        width: 300
    },
    {
        label: 'Vehicle Value',
        value: 'car_value',
        width: 150
    },
]

export const deleted_leads = [
    {
        label: 'Lead<br/>Created On',
        value: 'leads_created_at',
        isFixed: true,
        width: 180
    },
    {
        label: 'Lead<br/>Assigned To',
        value: 'leads_assigned_to',
        width: 180
    },
    {
        label: 'Lead Status',
        value: 'lead_status_text',
        width: 180
    },
    {
        label: 'Deleted Reason',
        value: 'deleted_reson',
        width: 180
    },
    {
        label: 'Lead<br/> Deleted On',
        value: 'deleted_at',
        width: 180
    },
    {
        label: 'Lead<br/> Deleted By',
        value: 'deleted_by',
        width: 180
    },
    {
        label: 'Lead Sources',
        value: 'lead_sources',
        width: 180
    },
    {
        label: 'Customer Name',
        value: 'customer_name',
        width: 180
    },
    {
        label: 'Email',
        value: 'customer_email',
        width: 180
    },
    {
        label: 'Phone Number',
        value: 'customer_phone_number',
        width: 180
    },
    {
        label: 'Vehicle Details',
        value: 'car_detail_name',
        width: 300
    },
    {
        label: 'Vehicle Value',
        value: 'car_value',
        width: 300
    },
    {
        label: 'Other Lead Sources',
        value: 'other_lead_source',
        width: 300
    },
    {
        label: 'UTM Source',
        value: 'utm_source',
        width: 150
    },
    {
        label: 'Other UTM Source',
        value: 'other_utm_source',
        width: 150
    },
    {
        label: 'UTM Medium',
        value: 'utm_medium',
        width: 150
    },
    {
        label: 'Other UTM Medium',
        value: 'other_utm_medium',
        width: 300
    },
    {
        label: 'UTM Campaign',
        value: 'utm_campaign',
        width: 150
    },
    {
        label: 'Other UTM Campaign',
        value: 'other_utm_campaign',
        width: 300
    },
    {
        label: 'UTM Content',
        value: 'utm_content',
        width: 150
    },
    {
        label: 'Other UTM Content',
        value: 'other_utm_content',
        width: 300
    },
]

export const production_report = [
    {
        label: 'Created On',
        value: 'created_at',
    },
    {
        label: 'Requested Date',
        value: 'requested_date',
    },
    {
        label: 'Requested By',
        value: 'user_name',
    },
]

export const renewal_status = [
    {
        label: 'Quote Ref No',
        value: 'quote_ref_no',
        width: 250
    },
    {
        label: 'Customer Id',
        value: 'customer_id',
        width: 200
    },
    {
        label: 'Lead Id',
        value: 'lead_id',
        width: 200
    },
    {
        label: 'Customer',
        value: 'customer_name',
        width: 300
    },
    {
        label: 'Car Details',
        value: 'car_details',
        width: 400
    },
    {
        label: 'Chassis No',
        value: 'chassis_no',
        width: 300
    },
    {
        label: 'Current Lead Status',
        value: 'current_lead_status',
        width: 250
    },
    {
        label: 'Insurance Plan (Provider - Type)',
        value: 'insurance_plan',
        width: 300
    },
    {
        label: 'Policy Expiry Date',
        value: 'policy_expiry_date',
        width: 250
    },
    {
        label: 'Policy SA',
        value: 'policy_sales_agent',
        width: 250
    },
    {
        label: 'Lead Sales Agent',
        value: 'lead_sales_agent',
        width: 250
    },
    
    
]

export const lost_lead = [
    {
        label: 'Customer Id',
        value: 'customer_id',
        width: 150
    },
    {
        label: 'Customer',
        value: 'customer_name',
        width: 250
    },
    {
        label: 'Lead Source',
        value: 'lead_source',
        width: 150
    },
    {
        label: 'Lead Status',
        value: 'lead_status',
        width: 200
    },
    {
        label: 'Sales Agent',
        value: 'agent',
        width: 200
    },
    {
        label: 'Lead Id',
        value: 'lead_id',
        width: 150,
        viewTask:true
    },
    {
        label: 'Lead Lost Date',
        value: 'lead_lost_date',
        width: 200
    },
    {
        label: 'Lead Lost Reason',
        value: 'reason',
        width: 200
    },
    {
        label: 'Notes',
        value: 'notes',
        width: 200
    },
    {
        label: 'Car Details',
        value: 'car_details',
        width: 400
    },
    
    {
        label: 'Created On',
        value: 'created_at',
        width: 200
    },
    {
        label: 'Updated On',
        value: 'updated_at',
        width: 200
    },
]

export const agent_escalation = [
    {
        label: 'Agent',
        value: 'agent_name',
    },
    {
        label: 'Escalation Date',
        value: 'created_at',
    },
    {
        label: 'New',
        value: 'new_count',
    },
    {
        label: 'QL',
        value: 'ql_count',
    },
    {
        label: 'Renewal',
        value: 'renewal_count',
    },
    {
        label: 'LLR',
        value: 'llr_count',
    },
    {
        label: 'Pending',
        value: 'pending_count',
    },
    {
        label: 'Total Count',
        value: 'total_count',
    },
   
]

export const underwriter_escalation = [
    {
        label: 'Underwriter',
        value: 'underwriter_name',
    },
    {
        label: 'Escalation Date',
        value: 'created_at',
    },
    {
        label: 'Assigned Policies',
        value: 'assigned',
    },
    {
        label: 'Cancellation Pending',
        value: 'cancellation_pending',
    },
    {
        label: 'Total Count',
        value: 'total_count',
    },
   
]

export const sales_tracking_report = [
    {
        label: 'Name',
        value: 'name',
        width: 180
    },
    {
        label: 'Revenue',
        value: 'el_total_revenue',
        width: 150
    },
    {
        label: 'Upto </br>Revenue Target',
        value: 'el_upto_revenue_target',
        width: 180
    },
    {
        label: 'Percentage',
        value: 'el_percentage',
        width: 150
    },
    {
        label: 'Deficit',
        value: 'el_deficit',
        width: 150
    },
    {
        label: 'Rank',
        value: 'el_rank',
        width: 150
    },
    {
        label: 'Total Target',
        value: 'el_total_target',
        width: 150
    },
    {
        label: 'Policies Issued',
        value: 'el_policies_issued',
        width: 150
    },
    {
        label: 'Upto </br>Policy Target',
        value: 'el_upto_policy_target',
        width: 150
    },
    {
        label: 'Policy Target',
        value: 'el_policy_target',
        width: 150
    },
    {
        label: '% of </br>Achievement',
        value: 'el_policy_percentage',
        width: 150
    },
    {
        label: 'Total Required',
        value: 'el_total_required',
        width: 150
    },
    {
        label: 'Per-day Required',
        value: 'el_perday_required',
        width: 150
    },
]

export const sales_team_target = [
    {
        label: 'Name',
        value: 'name',
        width: 180
    },
    {
        label: 'Total Target',
        value: 'el_total_target',
        width: 150
    },
    {
        label: 'Sales',
        value: 'el_total_revenue',
        width: 150
    },
    {
        label: 'Upto </br>Revenue Target',
        value: 'el_upto_revenue_target',
        width: 180
    },
    {
        label: 'Percentage',
        value: 'el_percentage',
        width: 150
    },
    {
        label: '% of Total</br>Achievement',
        value: 'el_percentage_total_achievement',
        width: 150
    },
    {
        label: 'Per-day Required',
        value: 'el_perday_required',
        width: 150
    },
    {
        label: 'Per-day Earned',
        value: 'el_perday_earned',
        width: 150
    },
    {
        label: 'Earning Rate </br>For the Month',
        value: 'el_earning_rate_for_month',
        width: 150
    },
    {
        label: 'Deficit',
        value: 'el_deficit',
        width: 150
    }
]