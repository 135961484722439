import store from "@/store";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class PolicyService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    PolicyService.vueInstance = app;
  }

  public static resetTempDoc() {
    store.commit(Mutations.SET_TEMP_DOCUMENTS, {});
    store.commit(Mutations.SET_POLICY_DOCUMENTS, []);
    store.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS, []);
    store.commit(Mutations.SE_TEMP_LOADING_DOC, true);
  }

  public static getPolicyList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICIES_LIST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })
    });
  }
  public static getRefundPolicyList(payload,) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_REFUND_POLICIES_LIST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }

  public static getDebitAmount() {
    store.dispatch(Actions.GET_SETTING_VALUE, null);
  }

  public static getRefundPolicyListCount(payload) {
    return new Promise<any>((resolve, reject) => {
      const countPayload = payload;
      // countPayload.status = "";
      store
        .dispatch(Actions.GET_REFUND_POLICIES_LIST_COUNT, countPayload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static getPolicyListCount(payload) {
    return new Promise<any>((resolve, reject) => {
      const countPayload = payload;
      // countPayload.status = "";
      store
        .dispatch(Actions.GET_POLICIES_LIST_COUNT, countPayload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static getLeadPolicies(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_POLICIES_LIST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })
    });
  }
  public static getLeadPoliciesLogs(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_LOGS, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })
    });
  }
  public static getPolicyDetails(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_DETAILS, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }
  public static uploadPolicy(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.UPLOAD_POLICY, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => { return reject(error) })
    });

  }
  public static getPolicyDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_DOCUMENTS, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }
  public static downloadZip(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.DOWNLOAD_ZIP_DOCUMENTS, payload)
        .then((data) => {
          console.log(data.data.file);
          // window.location.href = data.data.file;
          if(data.data.file) {
            window.open(
              data.data.file,
              '_blank' 
            );
          }
          resolve(data);
        })
        .catch((error) => { console.log(error); })
    });
  }
  public static getAllDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_ALL_DOCUMENTS, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });
  }
  public static getLeadDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_LEAD_DOCUMENTS, payload)
        .then((data) => { resolve(data) })
        .catch((error) => { reject(error) })
    });
  }
  public static getLeadActivePolicyDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_ACTIVE_LEAD_POLICY_DOCUMENTS, payload)
        .then((data) => { resolve(data) })
        .catch((error) => { reject(error) })
    });
  }
  public static deleteDocument(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.DELETE_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })
    });

  }
  public static getTempPolicyDocuments(payload, resetTempDoc = true) {
    payload.resetTempDoc = resetTempDoc;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_TEMP_POLICY_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { reject(error); })

    });
  }
  
  public static getTempPolicyCancelDocuments(payload, resetTempDoc = true) {
    payload.resetTempDoc = resetTempDoc;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_TEMP_POLICY_CANCEL_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { reject(error); })

    });
  }


  public static getPolicyCancellationDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_CANCELLATION_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }

  public static deleteTempDocument(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.DELETE_TEMP_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static updateDocType(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.UPDATE_TEMP_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static saveAsDraft(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.SAVE_AS_DRAFT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static getEmailList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_POLICY_MAIL_LIST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static emailCustomer(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.SEND_CUSTOMER_EMAIL, payload)
        .then((data) => { resolve(data); })
        .catch((error) => { reject(error); })

    });
  }
  public static brokerCustomer(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.SEND_BROKER_EMAIL, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })

    });
  }

  public static exportPolicyAssigned(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_POLICY_ASSIGNED, payload)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static transferSale(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.TRANSFER_SALE, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static transferUnderwriter(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.TRANSFER_UNDERWRITER, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static cancelPolicyRequest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.CANCEL_POLICY_REQUEST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static underwritterCancelPolicyRequest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.UNDERWRITTER_CANCEL_POLICY_REQUEST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }

  public static refundPolicyApproval(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.REFUND_POLICY_APPROVAL, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })

    });
  }
  public static cancelPolicyApproval(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.CANCEL_POLICY_APPROVAL, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); reject(error); })

    });
  }
  public static policyRefundRequest(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.POLICY_REFUND_REQUEST, payload)
        .then((data) => { console.log(data); resolve(data); })
      // .catch((error) => { console.log(error); })

    });
  }
  public static resetPolicyDocumentValues(payload) {

    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.RESET_POLICY_DOCUMET_STATUS, payload)


    });
  }

  public static getCancelledPoliciesList(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_CANCELLED_POLICIES_LIST, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static getPreviousPolicyDetails(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_PREVIOUS_POLICY_STATUS, payload)
        .then((data) => { console.log({ AAAA: data }); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static getPreviousPolicyDocuments(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_PREVIOUS_POLICY_DOCUMENTS, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }
  public static reUploadDocument(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.REUPLOAD_DOCUMENT, payload)
        .then((data) => { console.log(data); resolve(data); })
        .catch((error) => { console.log(error); })

    });
  }

  public static updateDataPolicy(payload) {
    return new Promise<any>((resolve, reject) => {
      store.dispatch(Actions.UPDATE_DATA_POLICY, payload)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        })
    })
  }

  public static resetPolicyError() {
    store.commit(Mutations.RESET_POLICY_ERROR);
  }

  public static setRefundModal(payload) {
    store.commit(Mutations.SET__REFUND_MODAL, payload);
  }

  public static setDiscrepanyModal(payload) {
    store.commit(Mutations.SET__DISCREPANCY_MODAL, payload);
  }

  public static resetPolicyRefundError() {
    store.commit(Mutations.SET__DISCREPANCY_MODAL_TEXT_ERROR, '');
  }

  public static async getOCR(payload) {
    return await store.dispatch(Actions.GET_OCR_VALUE, payload);

  }

  public static async getChildInvoiceByPolicy(payload) {
    return await store.dispatch(Actions.GET_CHILD_INVOICES_BY_POLICY, payload);
  }

  public static resetLeadsLog() {
    store.commit(Mutations.SET_POLICY_LOGS, [])
  }

  public static setCancelModal(payload) {
    store.commit(Mutations.SET_CANCEL_MODAL, payload);
  }

  public static resetPolicyDetails(payload) {
    store.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
    store.commit(Mutations.SET_POLICY_DOM_DETAILS, false);
    store.commit(Mutations.SET_POLICY_DETAILS, payload);
    store.commit(Mutations.SET_POLICY_CANCELLATION_DOCUMENTS,[]);
    store.commit(Mutations.SET_POLICY_DOCUMENT_DOM_STATUS, false);
  }
    
  public static setExtensionOpen(payload) {
    store.commit(Mutations.SET_EXTENSION_IS_OPEN, payload);
  }

  public static async getExtensionDocuments(payload) {
    return await store.dispatch(Actions.GET_EXTENSIONS, payload);
  }

  public static async uploadDocumentData(payload) {
    return await store.dispatch(Actions.UPLOAD_AMEND_DOCUMENT, payload);
  }

  public static setRefundCategory(payload) {
    store.commit(Mutations.SET_CATEGORY_REFUND, payload)
  }

  public static setRefundInvoiceId(payload) {
    store.commit(Mutations.SET_REFUND_INVOICE_ID, payload)
  }

  public static async getAllViewDocuments(payload) {
    return await store.dispatch(Actions.GET_VIEW_ALL_DOCUMENTS, payload)
  }

  public static resetViewAllModal() {
    store.commit(Mutations.SET_DATA_VIEW_ALL_DOCUMENTS, [])
    store.commit(Mutations.SET_OPEN_VIEW_ALL_DOCUMENTS, false)
  }

  public static setPolicyView(payload) {
    store.commit(Mutations.SET_POLICY_VIEW, payload);
  }
}


export default PolicyService;