
import moment from "moment";
import { defineComponent, nextTick, ref, watch, watchEffect } from "vue";

export default defineComponent({
  name: "custom-date-picker",
  props: {
    modelValue: {
      type: String,
      required: true,
      default: "",
    },
    min: {
      type: String,
      required: false,
      default: "",
    },
    max: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, context) {
    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
      nextTick(() => (firstDate.value = result));
    });

    // onMounted(() => {
    //   firstDate.value = moment(props.modelValue, "YYYY-MM-DD").format(
    //     "DD/MM/YYYY"
    //   );
    // });

    watchEffect(() => {
      firstDate.value = moment(props.modelValue, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    })

    const updateValue = () => {
      if (firstDate.value.length == 10) {
        let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
        context.emit("update:modelValue", myDate);
      }
    };

    const setDateFormat = () => {
      context.emit(
        "update:modelValue",
        moment(firstDate.value).format("YYYY-MM-DD")
      );
      let setDate = moment(firstDate.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      firstDate.value = setDate;
      context.emit('blur-out', firstDate.value)
    };

    const setDateFormatInput = () => {
      let dateMinEight = moment(firstDate.value, 'YYYYMMDD').format('YYYY-MM-DD')

      if(firstDate.value.length === 8 && dateMinEight != "Invalid date") {
        let setDateEight = moment(dateMinEight, "YYYY-MM-DD").format("DD/MM/YYYY");
        firstDate.value = setDateEight
        context.emit("update:modelValue", dateMinEight);
      }
      context.emit('blur-out', firstDate.value)
    };

    const changeValue = () => {
      let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
      const currentDate =
        props.max != ""
          ? props.max
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      const minDate = moment(props.min, "YYYY-MM-DD").format("DD/MM/YYYY");
      const setDate = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (firstDate.value.length != 10) {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (props.modelValue == "Invalid Date") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (myDate == "NaN-NaN-NaN") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      // console.log("change value");

      if (firstDate.value.length == 10 && myDate != "Invalid date") {
        if (
          props.max != "" &&
          myDate > moment(currentDate).format("YYYY-MM-DD")
        ) {
          // console.log("greater");
          // console.log({
          //   date: myDate,
          //   current: moment(currentDate).format("YYYY-MM-DD"),
          // });
          // firstDate.value = setDate;
          // context.emit("update:modelValue", currentDate);
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else if (
          props.min != "" &&
          myDate < moment(props.min).format("YYYY-MM-DD")
        ) {
          // console.log("else if");
          // console.log({
          //   datee: myDate,
          //   current: moment(currentDate).format("YYYY-MM-DD"),
          // });
          // firstDate.value = minDate;
          // context.emit(
          //   "update:modelValue",
          //   moment(props.min).format("YYYY-MM-DD")
          // );
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else {
          // console.log({
          //   dates: myDate,
          //   current: moment(currentDate).format("YYYY-MM-DD"),
          // });
          context.emit("update:modelValue", myDate);
        }
      } else {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      context.emit("vcBlur")
      context.emit("blur-out")
    };

    const focus = ref()

    const isFocus = () => {
      focus.value.focus()
    }

    return {
      focus,
      isFocus,
      firstDate,
      updateValue,
      changeValue,
      setDateFormat,
      setDateFormatInput,
    };
  },
});
