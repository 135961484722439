
import { defineComponent, onMounted, ref, computed } from "vue";
import PolicyService from "@/core/services/car/PolicyService";
import { useStore } from "vuex";
import MasterService from "@/core/services/car/MasterService";
import ReusableModal from '@/components/modals/forms/ReusableModal.vue';
import QuotesService from "@/core/services/car/QuotesService";

interface DownloadDocBodyParam {
  request_quote: Array<any>;
}

export default defineComponent({
  name: "quote-request-document-partials",
  components: {
    ReusableModal
  },
  props: {
    leadId: Number,
    categories: String,
    quoteDocs: Array,
    showHeader: Boolean,
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    isShow: {
      type: Boolean,
      required: false,
      default: false
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    showDownload: {
      type: Boolean,
      required: false,
      default: false
    },
    documentType: Array,
    ipList: Array,
    isViewOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    currentQuoteReqId: Number,
    selectedQuoteReqStatus: Boolean
  },
  data() {
    return {};
  },
  setup(props, context) {
    const store = useStore();
    let downloadDocBodyParam = {} as DownloadDocBodyParam;

    const insurancePolicesByTypes = computed(() => {
      return store.getters.getInsurancePolicyByType;
    });

    const fetchInsuranceCompanies = (event, doc) => {
      MasterService.getInsurancePolicyListByType({
        policy_type: event
      }).then((result)  =>{
        doc.insurance_policies = result.data.policies
      });
    }

    const updateQuoteRequestDocData = (event, docId) => {
      Promise.all([
        QuotesService.updateQRDocType({
          document_id: docId,
          car_insurance_id: event
        })
      ]).then((data) => {
        getQuoteDocs();
        console.log('');
      });
      context.emit("resetError");
    };

    const selected = ref();
    const openMouseEnter = ref(false);

    // const open = (e) => {
    //   selected.value = e;
    //   openMouseEnter.value = !openMouseEnter.value;
    // };

    function open(value: string) {
      window.open(value)
    }

    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const ApiPath = process.env.VUE_APP_API_URL;

    const itemData = ref(null);
    const isLoadingData = ref(false);
    const isOpen = ref(false);
    const currentPaymentSelected = ref();
    const OpenDelete = (id) => {
      itemData.value = id;
      isOpen.value = true;
    }

    const close = () => {
      itemData.value = null;
      isOpen.value = false;
      isLoadingData.value = false;
    }

    const getQuoteDocs = () => {
      // console.log(props.currentQuoteReqId);
      Promise.all([
        QuotesService.callingQuoteRequestDocs(
          {
            quote_request_id: props.currentQuoteReqId,
          },
        )
      ]).then((data) => {
        // console.log('')
      });
    };

    const deleteDocument = (docId) => {
      isLoadingData.value = true;
      Promise.all([
        QuotesService.deleteQuoteRequestDocument({
          id: docId
        })
      ]).then((data) => {
        close();
        getQuoteDocs()
      });
    };

    const loading = computed(() => {
      return store.getters.getLoadingTempDocument;
    })

    const downloadZip = (quoteDocs) => {
      let quoteDocsArray = Array<any>();
      quoteDocs.forEach((quoteDoc) => {
        quoteDocsArray.push(quoteDoc.id);
      });
      downloadDocBodyParam.request_quote = quoteDocsArray;
      PolicyService.downloadZip(downloadDocBodyParam);
    };

    const resetbutton = () => {
      currentPaymentSelected.value = null;
    }

    const updateQuoteRequestDocNotes = (doc) => {
      QuotesService.updateQuoteRequestDocNotes(doc);
    }

    const isEvenOdd = (val) => {
      if(val % 2 ==0) {
        return true;
      } else {
        return false;
      }
    }

    onMounted(() => {
      // MasterService.getInsuranceProviders();
    });

    return {
      resetbutton,
      loading,
      close,
      itemData,
      isOpen,
      isLoadingData,
      OpenDelete,
      ApiPath,
      open,
      selected,
      openMouseEnter,
      updateQuoteRequestDocData,
      userPermissions,
      fetchInsuranceCompanies,
      insurancePolicesByTypes,
      downloadZip,
      deleteDocument,
      currentPaymentSelected,
      updateQuoteRequestDocNotes,
      isEvenOdd,
    };
  }
});
