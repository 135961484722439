import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";


export interface MASTER {
    countryCodeList: Array<KEYOFSTRING>
    policyStartDateXDaysRestrict: number
    policyStartDateXDaysRestrictUpcoming: number
    carYears: Array<number>

}

interface KEYOFSTRING {
    [key: string]: string | number | null
}

@Module
export default class Master extends VuexModule implements MASTER{
    
    countryCodeList = []
    policyStartDateXDaysRestrict = 0
    policyStartDateXDaysRestrictUpcoming = 0
    carYears = []

    
    // actions
    @Action
    async ['GET_COUNTRY_CODES']() {
        try {
            const response = await ApiService.post('/skye/master/country-codes', {})
            this.context.commit('SET_COUNTRY_CODE_LIST', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_POLICY_START_DATE_DAYS_RESTRICTS']() {
        try {
        const response = await ApiService.post_get('/skye/settings/policy-start-date-days-restrict')
            if(response) {
                this.context.commit('SET_POLICY_START_DATE_DAYS_RESTRICT', response.data.data)
            }
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_YEARS'](payload) {
        try {
            const response = await ApiService.post('/skye/master/car-years', payload)
            this.context.commit('SET_CAR_YEARS', response.data.data.car_years)
            return response
        } catch (error) {
            return error
        }
    }

    // Mutations
    @Mutation
    ['SET_COUNTRY_CODE_LIST']({ codes }) {
        this.countryCodeList = codes
    }

    @Mutation
    ['SET_POLICY_START_DATE_DAYS_RESTRICT']({ setting, upcoming }) {
        this.policyStartDateXDaysRestrict = parseInt(setting.value)
        this.policyStartDateXDaysRestrictUpcoming = parseInt(upcoming.value)
    }

    @Mutation
    ['SET_CAR_YEARS'](car_years) {
        this.carYears = car_years
    }

    get getNewCarYears() {
        return (vehicle_type = 1) => {
            let carYear = <Array<KEYOFSTRING>>[{ label: `${vehicle_type == 1 ? 'Car Year' : 'Year'}`, value: 0 }]
          if(this.carYears.length > 0) carYear = this.carYears.map(x => { return { label: x, value: x } })
    
          const exist = carYear.some(x => x.value === 0)
          if(!exist) carYear.unshift({ label: `${vehicle_type == 1 ? 'Car Year' : 'Year'}`, value: 0 })
    
          
          return carYear
        }
      }
    

}