
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Action, Module, VuexModule } from "vuex-module-decorators";
import ApiService from "@/core/services/ApiService";
import IPaginationInfo from "@/models/IPaginationInfo";
import IUsers from "@/models/Users";
import { notif } from '@/store/stateless/store';
import axios from "axios";

export interface IDomSalesAgent{
    value:any;
    label:any;
}


export interface UserInfo {
    userUrl:UserUrlInfo;
    domSalesAgent:IDomSalesAgent;
    domSalesAgentManagerTeamLeader:IDomSalesAgent;
    paginationObj:IPaginationInfo;
    userList : IUsers;
    errors:Array<string>;
    editUserObj:[];
    domSkyeUsers:IDomSalesAgent;
}
export interface UserUrlInfo{
    addUserUrl:string;
    getUserListUrl:string;
    getRoles:string;
    getPolicyAgents:string;
    addUser:string;
    fetchUserUrl:string;
    editUserUrl:string;
    getDomSalesAgent:string;
    policySalesAgentUrl:string;
    getTeamLeadersList:string;
    getDomSkyeUsers:string;
}
export interface ITeamLeadersList{
    value: number;
    label: string;
    id: number;
    name: string;
    status: number;
    user_status: string;
}
export interface IRole {
  id: number;
  name: number;
  description: string;
}
export interface ISalesAgent {
    value: string;
    label: string;
    email: string;
  }

  export interface userObj {
    agentCount: number
    created_at: string
    customerCount:boolean
    deleted_at: string | null
    email: string
    email_verified_at: string | null
    failed_attempt: number
    id: number
    last_activity: string
    name: string
    office_mobile_number: string | null
    other_mobile_number: string | null
    policy_agent_id: number
    role_id: number
    roles: Array<number>
    round_robin_setting: roundRobinObj
    status: number
    team_leader_id: number
    updated_at: string
    user_status: string
  }

  export interface roundRobinObj {
    assign_ar_leads: number
    assign_car: number
    assign_en_leads: number
    assign_health: number
    assign_misc: number
    created_at: string
    deleted_at: string | null
    id: number
    is_all_deals: number
    is_archived:boolean
    is_both: number
    is_llr:boolean
    is_new: number
    is_renewal: number
    is_round_robin: number
    skip_round: number
    updated_at: string
    user_id: number
  }


@Module
export default class UserModule extends VuexModule{
    userUrl = {} as UserUrlInfo;
    paginationObj = {
        current_page: 1,
        data: [],
        first_page_url: "",
        from: 1,
        last_page: 1,
        last_page_url: "",
        links: [],
        next_page_url: "",
        path: "",
        per_page: "",
        prev_page_url: "",
        to: 1,
        total: 1,
    } as IPaginationInfo
    userList = {} as IUsers;
    errors = [];
    roles = [] as Array<IRole>;
    policyAgents = {} as IUsers;
    editUserObj =  {} as userObj;
    domSalesAgent = {} as IDomSalesAgent;
    domSalesAgentManagerTeamLeader = {} as IDomSalesAgent;
    domSkyeUsers = {} as IDomSalesAgent;
    policySalesAgents = {} as ISalesAgent;
    teamLeaderList = {} as ITeamLeadersList;
    activeDomSalesAgent = {} as IDomSalesAgent;
    activeUnderwriters = []
    
    @Action
    [Actions.GET_USER_LIST](payload){
      return new Promise<any>((resolve, reject) => { // 
        this.userUrl.getUserListUrl = (payload.index==1)?"skye/users-list":"skye/users-list?page="+payload.index;
        ApiService.post(this.userUrl.getUserListUrl,payload)
          .then(({ data }) => {
            debugger
            this.context.commit(Mutations.SET_USER_ERROR, []);
            this.context.commit(Mutations.SET_USER_LIST, data.data.users);
            resolve(data);
            
          })
          .catch(({ response }) => {// 
            this.context.commit(Mutations.SET_USER_ERROR, response);
            reject(response);
          });
      }); 
    }

    @Action
    [Actions.GET_ROLES]() {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getRoles = "/skye/user-roles";
            let payload: any;
            ApiService.post(this.userUrl.getRoles,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_ROLES,data.data.roles);
                resolve(data);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }

    @Action
    [Actions.GET_UNDERWRITERS_LIST](payload) {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getPolicyAgents = "/skye/underwriter-list";
            // let payload: any;
            ApiService.post(this.userUrl.getPolicyAgents,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_UNDERWRITERS_LIST,data.data.agents);
                resolve(data);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }
    @Action
    [Actions.GET_TEAM_LEADERS_LIST](){
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getTeamLeadersList = "/skye/team-leader-list";
            let payload: any;
            ApiService.post(this.userUrl.getTeamLeadersList,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_TEAM_LEADERS_LIST,data.data.team_leaders);
                resolve(data);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }
    @Action
    [Actions.GET_DOM_SALES_AGENTS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getDomSalesAgent = "/skye/agent-list-for-dom-new";
            ApiService.post(this.userUrl.getDomSalesAgent,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_DOM_SALES_AGENTS,data.data.agents);
                resolve(data);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }
    @Action
    [Actions.GET_DOM_SALES_AGENT_MANAGER_TEAMLEADER](payload) {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getDomSalesAgent = "/skye/agent-list-for-dom-new";
            ApiService.post(this.userUrl.getDomSalesAgent, payload)
            .then((result) => { 
                // console.log(result.data.data.agents)
                this.context.commit(Mutations.SET_DOM_SALES_AGENTS_MANAGER_TEAM_LEADER, result.data.data.agents);
                resolve(result);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }
    @Action
    [Actions.ADD_USER](payload){
        return new Promise<any>((resolve, reject) => {
            this.userUrl.addUser = "/skye/user-create";
            ApiService.post(this.userUrl.addUser,payload)
            .then(({data}) => { 
               
                this.context.commit(Mutations.SET_USER_ERROR, []);
                resolve(data);
            })
            .catch(({ response }) => { 
                let data = '';
                Object.entries(response.data.data).forEach(element => {
                    if(element[1] == "Please select all required for documents")
                    {
                      data = '<p>' + element[1] + '</p>'
                    }
                    else if (element[1] != false){
                      data += '<p>' + element[1] + '</p>'
                    }
                    
                });
                notif.simple('Please Note', 'warning', data, 0)
                this.context.commit(Mutations.SET_USER_ERROR, response.data.data);
                reject(response);
            });
        })
    }

    @Action
    [Actions.EDIT_USER](payload){
        return new Promise<any>((resolve, reject) => {
            this.userUrl.editUserUrl = "/skye/user/update/"+payload.id;
            ApiService.post(this.userUrl.editUserUrl,payload)
            .then(({data}) => { 
            
                resolve(data);
            })
            .catch(({ response }) => { 
                let data = '';
                Object.entries(response.data.data).forEach(element => {
                    if(element[1] == "Please select all required for documents")
                    {
                      data = '<p>' + element[1] + '</p>'
                    }
                    else if (element[1] != false){
                      data += '<p>' + element[1] + '</p>'
                    }
                    
                });
                notif.simple('Please Note', 'warning', data, 0)
                this.context.commit(Mutations.SET_USER_ERROR, response.data.data);
                reject(response);
            });
        })
    }
    @Action
    async[Actions.FETCH_USER](payload) {
        // return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_USER_DOM,false);
            this.userUrl.fetchUserUrl = "/skye/user/show/"+payload;
           
            // ApiService.post(this.userUrl.fetchUserUrl,payload)//,payload
            // .then(({data}) => { 
            //    //  
            //    console.log({user: data.data})
            //     this.context.commit(Mutations.SET_EDIT_USER,data.data.user);
            //     this.context.commit(Mutations.SET_USER_DOM,true);
            //     resolve(data);
               
            //     // this.context.commit(Mutations.SET_UNDERWRITERS_LIST,data.data.agents);
            // })
            // .catch(({ response }) => {
            //     //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
            //     reject(response);
            // });
            try {
                const data = await ApiService.post(this.userUrl.fetchUserUrl, payload)
                // console.log({user: data.data})
                this.context.commit(Mutations.SET_EDIT_USER,data.data.data.user);
                this.context.commit(Mutations.SET_USER_DOM,true);
                return data
            } catch (err) {
                return err
            }
        // })
    }

    @Action
    [Actions.GET_POLICY_SALES_AGENT](payload) {
        return new Promise<any>((resolve, reject) => {
            this.context.commit(Mutations.SET_USER_DOM,false);
            this.userUrl.policySalesAgentUrl = "skye/policy-agent-list";
           
            ApiService.post(this.userUrl.policySalesAgentUrl,payload)//,payload
            .then(({data}) => { 
               //  
                this.context.commit(Mutations.SET_EDIT_USER,data.data.user);
                this.context.commit(Mutations.SET_USER_DOM,true);
                resolve(data);
               
                // this.context.commit(Mutations.SET_UNDERWRITERS_LIST,data.data.agents);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }

    @Action
    [Actions.GET_DOM_SKYE_USERS]() {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getDomSkyeUsers = "/skye/users-list-for-dom";
            let payload: any;
            ApiService.post(this.userUrl.getDomSkyeUsers,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_DOM_SKYE_USERS,data.data.users);
                resolve(data);
            })
            .catch(({ response }) => {
                reject(response);
            });
        })
    }

    @Action
    [Actions.GET_AGENT_BY_SETTING](payload) {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getDomSkyeUsers = "/skye/get-agent-setting-list";
            console.log('payload',payload)
            ApiService.post(this.userUrl.getDomSkyeUsers,payload)
            .then((result) => {  
                this.context.commit(Mutations.SET_DOM_SALES_AGENTS_MANAGER_TEAM_LEADER, result.data.data.agents);
                resolve(result);
            })
            .catch(({ response }) => {
                reject(response);
            });
        })
    }

    @Action
    [Actions.GET_ACTIVE_DOM_SALES_AGENTS](payload) {
        return new Promise<any>((resolve, reject) => {
            this.userUrl.getDomSalesAgent = "/skye/agent-list-for-dom-new";
            ApiService.post(this.userUrl.getDomSalesAgent,payload)
            .then(({data}) => { 
                this.context.commit(Mutations.SET_ACTIVE_DOM_SALES_AGENTS,data.data.agents);
                resolve(data);
            })
            .catch(({ response }) => {
                //this.context.commit(Mutations.SET_USER_ERROR, response.data.errors);
                reject(response);
            });
        })
    }

    @Action
    async [Actions.GET_ACTIVE_UNDERWRITERS]() {
        try {
            const data = await ApiService.post_get('/skye/get-active-underwriters')
            this.context.commit(Mutations.SET_ACTIVE_UNDERWRITERS, data.data.data.users)
            return data.data.data.users
        } catch (error) {
            console.log(error)
        }
    }

    @Mutation
    [Mutations.SET_TEAM_LEADERS_LIST](payload): void {
        this.teamLeaderList = payload;
    }

    @Mutation
    [Mutations.SET_ACTIVE_UNDERWRITERS](payload) {
        this.activeUnderwriters = payload;
    }
    @Mutation
    [Mutations.SET_POLICY_SALES_AGENT_LIST](payload) : void { 
      this.policySalesAgents = payload.data.agents;
    }
    @Mutation
    [Mutations.SET_USER_ERROR](error) { 
        const errors = [] as  any;
        for (const key in error) {
      
          errors.push( error[key]);
         
        }
        this.errors = errors;
    }
    @Mutation
    [Mutations.SET_DOM_SALES_AGENTS](payload){
      // 
      this.domSalesAgent = payload;
    }
    @Mutation
    [Mutations.SET_DOM_SALES_AGENTS_MANAGER_TEAM_LEADER](payload){
      // 
      this.domSalesAgentManagerTeamLeader = payload;
    }
    
    @Mutation
    [Mutations.SET_USER_LIST](payload){
      // 
      this.userList = payload.data;
      this.paginationObj.links = payload.links;
      this.paginationObj.first_page_url = payload.first_page_url;
      this.paginationObj.last_page_url = payload.last_page_url;
      this.paginationObj.from = payload.from;
      this.paginationObj.to = payload.to;
      this.paginationObj.next_page_url = payload.next_page_url;
      this.paginationObj.prev_page_url = payload.prev_page_url;
      this.paginationObj.total = payload.total;
    }

    @Mutation
    [Mutations.SET_ROLES](payload){
      // 
      this.roles = payload;
 
    }

    @Mutation
    [Mutations.SET_UNDERWRITERS_LIST](payload){
     
        this.policyAgents = payload;
 
    }

    @Mutation
    [Mutations.SET_EDIT_USER](payload: userObj) {
      // 
       this.editUserObj = payload;
      
    }

    
    @Mutation
    [Mutations.SET_DOM_SKYE_USERS](payload){
      // 
      this.domSkyeUsers = payload;
    }
    @Mutation
    [Mutations.SET_ACTIVE_DOM_SALES_AGENTS](payload){
      
      this.activeDomSalesAgent = payload;
    }

    get getPolicySalesAgentList(): ISalesAgent {
        return this.policySalesAgents;
      }
    get getUserErrors(): Array<string> {
      return this.errors;
    }
    get getDomSalesAgent(): IDomSalesAgent {
        return this.domSalesAgent;
    }
    get getDomSalesAgentManagerTeamLeader(): IDomSalesAgent {
        return this.domSalesAgentManagerTeamLeader;
      }
    get getEditUsers(): userObj { 
      return this.editUserObj;
    }
    get getUnderWriters(): IUsers { 
        return this.policyAgents;
    }

    get getPaginationObj(): IPaginationInfo {
        return this.paginationObj;
    }

    get getDomSkyeUsers(): IDomSalesAgent {
        return this.domSkyeUsers;
    }

    get getActiveSalesAgent(): IDomSalesAgent {
        return this.activeDomSalesAgent;
    }

    get getActiveUnderwriters() {
        return this.activeUnderwriters
    }

}
