import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { common } from '@/store/stateless/common'

interface keyVariable {
    [key: string] : string | number | undefined
}

@Module
export default class Dashboard extends VuexModule {
    row_1 = [
        {
            label: "Total Sales",
            icon: "icon-svg icon-total-sales",
            value_1: "0",
            style_1: "rgb(0, 197, 220) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(0, 197, 220) !important",
            class_2: "fw-light fs-2",
            top_class: "total-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-sales"
        },
        {
            label: "Total Leads",
            icon: "icon-svg icon-total-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "total-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "third-party",
            col: "2"
        },
        {
            label: "Total Revenue",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "total-revenue",
            col: "2"
        }
    ]
    row_2 = [
        {
            label: "New Sales",
            icon: "icon-svg icon-new-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "new-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-new-sales"
        },
        {
            label: "New Leads",
            icon: "icon-svg icon-new-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "new-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-new-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "new-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-revenue",
            col: "2"
        }
    ]
    row_3 = [
        {
            label: "WIP Sales",
            icon: "icon-svg icon-new-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "new-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-wip-sales"
        },
        {
            label: "WIP (New | Rwl | Oth)",
            icon: "icon-svg icon-new-sales",
            value_1: "0 | 0 | 0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "new-sales",
            col: "2"
        },
        {
            is_empty: true,
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-closing-ratio",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "new-revenue",
            col: "2"
        }
    ]
    row_4 = [
        {
            label: "Renewal Sales",
            icon: "icon-svg icon-renewal-sales",
            value_1: "0",
            class_1: "fw-bold cursor-pointer",
            style_1: "rgb(39, 57, 193) !important",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "renewal-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-renewal-sales"
        },
        {
            label: "Renewal Leads",
            icon: "icon-svg icon-renewal-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "renewal-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-renewal-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "renewal-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "renewal-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "renewal-revenue",
            col: "2"
        }
    ]
    row_5 = [
        {
            label: "Lost Lead Renewal Sales",
            icon: "icon-svg icon-llr-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "llr-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=total-llr-sales"
        },
        {
            label: "Lost Lead Renewal Leads",
            icon: "icon-svg icon-llr-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            value_2: "(0)",
            style_2: "rgb(39, 57, 193) !important",
            class_2: "fw-light fs-2",
            top_class: "llr-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-llr-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "llr-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "llr-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "llr-revenue",
            col: "2"
        }
    ]
    row_6 = [
        {
            label: "Lost Leads",
            icon: "icon-svg icon-lrl-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lrl-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-lost-leads"
        },
        {
            label: "Lead Renewal Lost",
            icon: "icon-svg icon-lrl-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lrl-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=total-lrl-leads"
        },
        {
            label: "LL / LRL to Deal",
            icon: "icon-svg icon-new-sales",
            value_1: "0 / 0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "lrl-closing-ratio",
            col: "2"
        },
        {
            label: "HL/RHL",
            icon: "icon-svg icon-u-rhl",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lrl-closing-ratio",
            col: "2"
        },
        {
            label: "WIP",
            icon: "icon-svg icon-u-wip",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "lrl-third-party",
            col: "2"
        },
        // {
        //     is_empty: true,
        //     col: "2"
        // }
    ]
    row_7 = [
        {
            label: "Current Year Renewal Sales",
            icon: "icon-svg icon-lr-sales",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lr-sales",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&sales=lifetime-renewal-leads"
        },
        {
            label: "Previous Year Renewal Leads",
            icon: "icon-svg icon-lr-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold cursor-pointer",
            top_class: "lr-leads",
            col: "2",
            link: "start_date=DATE_START&end_date=DATE_END&leads=lifetime-renewal-leads"
        },
        {
            label: "Closing Ratio",
            icon: "icon-svg icon-closing-ratio",
            value_1: "0%",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "lr-closing-ratio",
            col: "2"
        },
        {
            label: "Comp / Third Party",
            icon: "icon-svg icon-third-party",
            value_1: "0 / 0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lr-third-party",
            col: "2"
        },
        {
            label: "Revenue",
            icon: "icon-svg icon-new-revenue",
            value_1: "0",
            style_1: "rgb(52, 191, 163) !important",
            class_1: "fw-bold",
            top_class: "lr-revenue",
            col: "2"
        }
    ]
    row_8 = [
        {
            label: "Untouched Customers",
            icon: "icon-svg icon-u-customers",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-customer",
            col: "2"
        },
        {
            label: "Untouched Renewal Leads",
            icon: "icon-svg icon-u-renewal-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-renewal-leads",
            col: "2"
        },
        {
            label: "Untouched New Leads",
            icon: "icon-svg icon-u-new-leads",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-new-leads",
            col: "2"
        },
        {
            label: "Untouched LLR",
            icon: "icon-svg icon-u-llr",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "u-llr",
            col: "2"
        }
    ]
    row_9 = [
        {
            label: "Total Refund",
            icon: "icon-svg icon-refund",
            value_1: "0 | 0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund",
            col: "2"
        },
        {
            label: "Partial Refund",
            icon: "icon-svg icon-partial-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-refund",
            col: "2"
        },
        {
            label: "Full Refund",
            icon: "icon-svg icon-total-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund",
            col: "2"
        },
        {
            label: "Total Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-refund-amt",
            col: "2"
        },
        {
            label: "Partial Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-refund-amt",
            col: "2"
        },
        {
            label: "Full Refund Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-full-amt",
            col: "2"
        }
    ]
    row_10 = [
        {
            label: "Total Cancellation",
            icon: "icon-svg icon-policies-cancelled",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation",
            col: "2"
        },
        {
            label: "Partial Cancellation",
            icon: "icon-svg icon-partial-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-cancellation",
            col: "2"
        },
        {
            label: "Full Cancellation",
            icon: "icon-svg icon-total-refund",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation",
            col: "2"
        },
        {
            label: "Total Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "total-cancellation-amt",
            col: "2"
        },
        {
            label: "Partial Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "partial-cancellation-amt",
            col: "2"
        },
        {
            label: "Full Cancellation Amount",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(39, 57, 193) !important",
            class_1: "fw-bold",
            top_class: "full-cancellation-amt",
            col: "2"
        }
    ]
    row_11 = [
        {
            label: "Total Revenue <span class='pt-1 fs-7'>(Inclusive Addons)</span>",
            icon: "icon-svg icon-total-revenue",
            value_1: "0",
            style_1: "rgb(113, 106, 202) !important",
            class_1: "fw-bold",
            top_class: "total-revenue",
            col: "2"
        },
        {
            label: "Addons",
            icon: "icon-svg icon-addson",
            value_1: "0",
            style_1: "rgb(244, 81, 108) !important",
            class_1: "fw-bold",
            top_class: "addson",
            col: "2"
        }
    ]

    @Action
    ['DASHBOARD_OVERVIEWS'](payload) {
        Promise.all([
            ApiService.post('/skye/dashboard/overview/row_1', payload),
            ApiService.post('/skye/dashboard/overview/row_2', payload),
            ApiService.post('/skye/dashboard/overview/row_3', payload),
            ApiService.post('/skye/dashboard/overview/row_4', payload),
            ApiService.post('/skye/dashboard/overview/row_5', payload),
            ApiService.post('/skye/dashboard/overview/row_6', payload),
            ApiService.post('/skye/dashboard/overview/row_7', payload),
            ApiService.post('/skye/dashboard/overview/row_8', payload),
            ApiService.post('/skye/dashboard/overview/row_9', payload),
            ApiService.post('/skye/dashboard/overview/row_10', payload),
        ])
        .then(([
            row_1, 
            row_2, 
            row_3, 
            row_4, 
            row_5, 
            row_6, 
            row_7, 
            row_8, 
            row_9, 
            row_10
        ]) => {
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_1', row_1.data.data.row_1)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_2', row_2.data.data.row_2)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_3', row_3.data.data.row_3)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_4', row_4.data.data.row_4)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_5', row_5.data.data.row_5)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_6', row_6.data.data.row_6)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_7', row_7.data.data.row_7)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_8', row_8.data.data.row_8)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_9', row_9.data.data.row_9)
            this.context.commit('SET_DASHBOARD_OVERVIEWS_ROW_10', row_10.data.data.row_10)
        }) 
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_1'](row) {
        this.row_1 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_2'](row) {
        this.row_2 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_3'](row) {
        this.row_3 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_4'](row) {
        this.row_4 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_5'](row) {
        this.row_5 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_6'](row) {
        this.row_6 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_7'](row) {
        this.row_7 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_8'](row) {
        this.row_8 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_9'](row) {
        this.row_9 = row
    }

    @Mutation
    ['SET_DASHBOARD_OVERVIEWS_ROW_10'](row) {
        this.row_10 = row
    }
    
    get getCarDBoxDashboard() {
        return (data, user_id, role_id) => {
            return {
                card_box_1: cardBox_1(data.topSalesPerson.result, user_id, role_id),
                card_box_2: cardBox_2(data.topSalesPerson.result, user_id, role_id),
                card_box_3: createAgentGraph(data.topSalesPerson.resultPerformance),
                show_agent_dashboard: data.topSalesPerson.resultPerformance.length > 0 ? true : false,
                team_summary: createTeamSummary(data.topSalesPerson.result)
            }
        }
    }
    
    get getCarDBoxOmDashboard() {
        return (data, user_id, role_id) => {
            return {
                card_box_1: cardBox_1(data.topSalesPerson.result, user_id, role_id),
                card_box_2: cardBox_2(data.topSalesPerson.result, user_id, role_id),
                card_box_3: createOMGraph(data.topSalesPerson.resultPerformance),
                show_agent_dashboard: data.topSalesPerson.resultPerformance.length > 0 ? true : false,
                team_summary: createTeamSummary(data.topSalesPerson.result)
            }
        }
    }

    get getCardBoxUnderwriterDashboard() {
        return (data, user_id)  =>{
            return {
                card_box_1: underwriterBox_1(data, user_id),
                card_box_2: underwriterBox_2(data, user_id),
                card_box_3: underwriterBox_3(data, user_id),
            }
        }
    }

    get getSalesPersonInfoDashboard() {
        return (data) => {
                return {
                    top_sales_person: getTopSalesPerson(data.topSalesPerson.result),
                    sales_target_summary: getSalesTargetSummary(data.salesTargetSummary),
                    leads_target_summary: getLeadsTargetSummary(data.leadTargetSummary)
                }
        }
    }

    get getGraphStatisticsDashboard() {
        return (data) => {
            const dObj = {}
            for (const graphs in data) {
                const fObj = <any>data[graphs]
                for(const objs in fObj) {
                    dObj[`${graphs}_${objs}`] = createGraph(createSeries(fObj[objs], 'count'), createGraphObj(fObj[objs], 'colors'), createGraphObj(fObj[objs], 'names'))
                }
            }

            return dObj
        }
    }

    get getTableStatisticsDashboard() {
        return (data) => {
            if(data) {
                return {
                    top_providers: data.topProviders.result,
                    top_nationality: data.topNationality.result,
                    top_brand_policy_sold: data.topBrands.result,
                    top_model_policy_sold: data.topModels.result,
                    discount_code: data.topDiscounts.result.map(x => {
                        return {
                            ...x,
                            status: x.code_value_type == 1 ? 'Active' : 'Inactive'
                        }
                    }),
                    policies_sold_coupons: {
                        "With Coupons": data.getPoliciesSoldWowCoupon.result.policies_sold_with_coupon,
                        "Without Coupons": data.getPoliciesSoldWowCoupon.result.policies_sold_without_coupon
                    }
                }
            }

            return {}
        }
    }

    get getOtherInfoDashboard() {
        return (data) => {
            if(data) {
                return {
                    user_logs: createOtherInfoTable(data.userRecentLog, 'userLogs'),
                    new_leads: createOtherInfoTable(data.leadsNew, 'newLeads', 'New'),
                    pending_leads: createOtherInfoTable(data.leadsPending, 'newLeads', 'Pending'),
                    lost_leads: createOtherInfoTable(data.leadsLost, 'newLeads', 'Lost'),
                    cod_transactions: createOtherInfoTable(data.listCodTransactions.result, 'transaction', 'COD Txns'),
                    online_transactions: createOtherInfoTable(data.listOnlineTransactions.result, 'transaction', 'Online Txns'),
                    direct_transactions: createOtherInfoTable(data.listDirectTransactions.result, 'transaction', 'Direct Txns'),
                    policies_comprehensive: {
                        data: {
                            "Total Amount": data.getPolicyData.result.comprehensive_amount,
                            "Average Amount": data.getPolicyData.result.comprehensive_amount_avg,
                            "Avareage Age": data.getPolicyData.result.comprehensive_average_customer_age,
                            "Sold Policies": data.getPolicyData.result.comprehensive_sold_count,
                        }
                    },
                    policies_tpl: {
                        data: {
                            "Total Amount": data.getPolicyData.result.third_party_amount,
                            "Average Amount": data.getPolicyData.result.third_party_amount_avg,
                            "Avareage Age": data.getPolicyData.result.third_party_average_customer_age,
                            "Sold Policies": data.getPolicyData.result.third_party_sold_count,
                        }
                    },
                    average_quoted_vehicle: {
                        data: {
                            "Car Value": data.getAverageQuotedVehicle.result.average_car_value
                        }
                    },
                    closing_ratio: {
                        data: {
                            "Total Deals": data.getClosingRatio.result.deals,
                            "Total Leads": data.getClosingRatio.result.leads,
                        },
                        percentage: `${data.getClosingRatio.result.closing_ratio}%`
                    },
                    sms_stat: {
                        data: {
                            "Sent": data.smsStats.total_sent
                        }
                    },
                }

            }

            return {}
        }
    }

    get getCarBoxAccountDashboard() {
        return (data) => {
            return accountBox(data)
        }
    }

    get getCardBoxMarketAdminDashboard() {
        return (data) => {
            return marketingBox(data)
        }
    }

}

function getTopSalesPerson(data) {
    const salesPerson = data.map((x) => {
        return {
            ...x,
            new_leads: x.new_leads,
            new_deals: x.new_deals,
            wip_deals: x.wip_deals + ' (' + x.wip_new + ' | ' + x.wip_renewal + ' | ' + x.wip_others + ')' ,
            renewal_leads: x.renewal_leads,
            renewal_deals: x.renewal_deals,
            llr_leads: x.llr_leads,
            llr_deals: x.llr_deals,
            total_leads: x.total_leads,
            total_deals: x.total_deals,
            total_revenue: x.total_revenue,
            lost_leads: x.lost_leads,
            untouched_leads: x.untouched_leads,
            lrl_leads: x.lrl_leads,
            untouched_customers: x.untouched_customers,
            over_due_tasks: x.over_due_tasks,
            pending_leads: x.pending_leads,
            total_deals_cancelled: x.total_deals_cancelled,
            closing_ratio_new: `${x.closing_ratio_new}%`,
            closing_ratio_renewal: `${x.closing_ratio_renewal}%`,
            closing_ratio_llr: `${x.closing_ratio_llr}%`,
            closing_ratio_total: `${x.closing_ratio_total}%`,
            target_percentage_met: `${x.target_percentage_met}%`,
            deals_cancellation_payable_amount: x.deals_cancellation_payable_amount ? currency(x.deals_cancellation_payable_amount) : '0.00'
        }
    })

    const total = getTotalSalesPerson(data)
    salesPerson.push(total)
    return salesPerson
}

function getTotalSalesPerson(datas) {
    if(datas) {
        const total_deals = calculateTotal(datas, 'total_deals')
        const total_leads = calculateTotal(datas, 'total_leads')
        const new_deals = calculateTotal(datas, 'new_deals')
        const new_leads = calculateTotal(datas, 'new_leads')
        const renewal_deals = calculateTotal(datas, 'renewal_deals')
        const renewal_leads = calculateTotal(datas, 'renewal_leads')
        const closing_total = roundUp((total_deals / total_leads) * 100)
        const closing_new = roundUp((new_deals / new_leads) * 100)
        const closing_renewal = roundUp((renewal_deals / renewal_leads) * 100)
        const lost_leads = calculateTotal(datas, 'lost_leads')
        const lrl_leads = calculateTotal(datas, 'lrl_leads')
        const llr_deals = calculateTotal(datas, 'llr_deals')
        const closing_llr = roundUp((llr_deals / lrl_leads) * 100)
        const wip_deals = calculateTotal(datas, 'wip_deals')
        const wip_new = calculateTotal(datas, 'wip_new')
        const wip_renewal = calculateTotal(datas, 'wip_renewal')
        const wip_others = calculateTotal(datas, 'wip_others')
        const revenue_target = calculateTotal(datas, 'revenue_target')
        const revenue_target_achieved = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target) * 100)
        const revenue_target_no_of_days = calculateTotal(datas, 'revenue_target_no_of_days')
        const total_target_percentage_met = roundUp((calculateTotal(datas, 'total_revenue') / revenue_target_no_of_days) * 100)
        const is_target_flag = isFlagTrue(datas, 'is_target_flag')
        return {
            agent_name: 'Total',
            total_leads: total_leads,
            total_deals: total_deals,
            new_leads: new_leads,
            renewal_leads: renewal_leads,
            llr_leads: calculateTotal(datas, 'llr_leads'),
            pending_leads: calculateTotal(datas, 'pending_leads'),
            untouched_customers: calculateTotal(datas, 'untouched_customers'),
            untouched_leads: calculateTotal(datas, 'untouched_leads'),
            over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
            new_deals: new_deals,
            renewal_deals: renewal_deals,
            total_deals_cancelled: calculateTotal(datas, 'total_deals_cancelled'),
            deals_cancellation_payable_amount: currency(roundUp(calculateTotal(datas, 'deals_cancellation_payable_amount'))),
            total_revenue: currency(roundUp(calculateTotal(datas, 'total_revenue'))),
            lost_leads: lost_leads,
            lrl_leads: lrl_leads,
            llr_deals: llr_deals,
            wip_deals: wip_deals + ' (' + wip_new + ' | ' + wip_renewal + ' | ' + wip_others + ')',
            closing_ratio_total: isFinite(closing_total) ? `${roundUp(closing_total)}%` : `0.00%`,
            closing_ratio_new: isFinite(closing_new) ? `${roundUp(closing_new)}%` : `0.00%`,
            closing_ratio_renewal: isFinite(closing_renewal) ? `${roundUp(closing_renewal)}%` : `0.00%`,
            closing_ratio_llr: isFinite(closing_llr) ? `${roundUp(closing_llr)}%` : `0.00%`,
            revenue_target_achieved: isFinite(revenue_target_achieved) ? `${roundUp(revenue_target_achieved)}%` : `0.00%`,
            target_percentage_met: isFinite(total_target_percentage_met) ? `${roundUp(total_target_percentage_met)}%` : (is_target_flag ? `0.00%` : '-'),
            revenue_target_no_of_days: isFinite(revenue_target_no_of_days) ? `${roundUp(revenue_target_no_of_days)}` : `-`,
            is_target_flag:is_target_flag
        }
    }

    return {}
}

function getSalesTargetSummary(data) {
    return data.map(x => {
        return {
            ...x,
            total_deals: x.total_deals,
            sales_target: x.sales_target,
            refund: currency(x.refund),
            revenue_made: currency(x.revenue_made),
            revenue_target: currency(x.revenue_target)
        }
    })
}

function getLeadsTargetSummary(data) {
    return data.map(x => {
        return {
            ...x,
            no_of_leads: x.no_of_leads,
            achieved: x.achieved,
            marketing_spend: currency(x.marketing_spend)
        }
    })
}

function calculateTotal(data, column) {
    return data.reduce((total, item) => {
            if(column  == 'revenue_target'){
                item[column] = item[column].replaceAll(',','');
            } 
            if(typeof item[column] === 'string') {
                const value = item[column].replace(',', '')
                return total += parseFloat(value)
            } else {
                return total += item[column]
            }
    }, 0)
}

function createGraph(series, colors, labels) {
    return {
        "series": series,
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                    "type": "pie"
                },
                "colors": colors,
                "labels": labels,
                "responsive": [{
                    "breakpoint": 400,
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
    }
}

function createAgentGraph(obj) {
    const series = obj.map(x => {
        return parseFloat(x.total_revenue);
    });
    const lables = obj.map(x => {
        return x.agent_name
    });

    return {
        "series": series,
            "options": {
                "chart": {
                    "width": 450,
                    "height": 250,
                    "type": "pie"
                },
                "labels": lables,
                "responsive": [{
                    "breakpoint": 450,
                    "options": {
                        "chart": {
                            "width": 450,
                            "height": 250,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
    }
}

function createOMGraph(obj) {
    const series = obj.map(x => {
        return parseFloat(x.total_revenue);
    });
    const lables = obj.map(x => {
        return x.agent_name
    });

    return {
        "series": series,
            "options": {
                "chart": {
                    "width": 800,
                    "height": 600,
                    "type": "pie"
                },
                "labels": lables,
                "responsive": [{
                    "breakpoint": 800,
                    "options": {
                        "chart": {
                            "width": 800,
                            "height": 600,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
    }
}

function createSeries(obj, column) {
    if(obj && obj[column]) {
        return obj[column].filter(x => x).map(x => parseInt(x))
    }

    return []
}

function createGraphObj(obj, column) {
    if(obj) {
        return obj[column]
    }

    return []
}

function createOtherInfoTable(obj, type, name = '') {
    if(!obj) {
        return {
            data: [],
            total: 0,
            current_page: 0,
            per_page: 0,
            last_page: 0,
            topValues: {
                title: '',
                value: 0
            }
        }
    }
    return {
        data: obj.data && obj.data.length > 0 ? obj.data.map(x => {
            if(type == 'userLogs') return userLog(x)
            else if(type == 'newLeads') return newLeads(x)
            else if(type == 'transaction') return transactionLeads(x)
        }) : [],
        total: obj.total,
        current_page: obj.current_page,
        per_page: obj.per_page,
        last_page: obj.last_page,
        topValues: {
            title: name,
            value: obj.total
        }
    }
}

function userLog(x) {
    return {
        name: x.name,
        date_time: x.recent_user_log ? x.recent_user_log.login_date_time_format : null,
        ip_address: x.recent_user_log ? x.recent_user_log.ip_address : null,
        browser: x.recent_user_log ? x.recent_user_log.browser_type : null,
        lat: x.recent_user_log ? x.recent_user_log.latitude : null,
        long: x.recent_user_log ? x.recent_user_log.longitude : null,
        status: x.user_status
    }
}

function newLeads(x) {
    if(x.customer) {
        return {
            name: x.customer.name,
            email: x.customer.email,
            mobile: x.customer.phone_number,
            joined: x.customer.created_at,
        }
    }

    return {}
}

function transactionLeads(x) {
    return {
        quote_ref_no: x.merchant_reference,
        policy: x.policy_name,
        policy_price: ` CURRENCY ${common.formatCurrency(x.amount)}`
    }
}


function roundUp(num) {
    return common.roundUp(num)
}

function isFlagTrue (data, column) {
    return data.map((item) => {
       return item[column]
    }, false)
}

function currency (e, digit = 2) {
    return common.formatCurrency(e, digit)
}

function cardBox_1(data, user_id, role_id) {
    let item = <any>{}
    if(Array.isArray(data)) item = data.find(x => x.agent == user_id)
    else item = data

    if(role_id == 15) item = {
        total_revenue: 0,
        sales_target: 0,
        total_deals: 0,
        variance: 0,
        closing_ratio_total: 0,
        new_leads: 0,
        quick_leads: 0,
        pending_leads: 0
    }
    
    if(item) {
        return [
            {
                label: "Revenue",
                icon: "icon-svg icon-revenue",
                value_1: `${currency(item.total_revenue)} / ${currency(item.sales_target * 1000)}`,
                style_1: "#34bfa3 !important",
                class_1: "dashboard-text-light-green",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
            {
                label: "Deals / Target",
                icon: "icon-svg icon-sales-target",
                value_1: `${item.total_deals} / ${item.sales_target}`,
                style_1: "#f1416c !important",
                class_1: "text-danger",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
            {
                label: "Variance",
                icon: "icon-svg icon-variance",
                value_1: `${item.variance}`,
                style_1: "#f1416c !important",
                class_1: "text-danger",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
            {
                label: "Closing Ratio",
                icon: "icon-svg icon-closing-ratio",
                value_1: `${currency(item.closing_ratio_total)}%`,
                style_1: "#f1416c !important",
                class_1: "text-danger",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
            {
                label: "New Leads | Quick Leads",
                icon: "icon-svg icon-new-leads",
                value_1: `${item.new_leads} | ${item.quick_leads}`,
                style_1: "#2739c1 !important",
                class_1: "dashboard-text-blue",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
            {
                label: "Pending",
                icon: "icon-svg icon-pending",
                value_1: `${item.pending_leads}`,
                style_1: "#2739c1 !important",
                class_1: "dashboard-text-blue",
                top_class: "sales-dashboard-item pe-0",
                col: 2, 
            },
        ]
    }
}

function cardBox_2(data, user_id, role_id) {
    let item = <any>{}
    if(Array.isArray(data)) item = data.find(x => x.agent == user_id)
    else item = data

    if(role_id == 15) item = {
        renewal_leads: 0,
        llr_leads: 0,
        lrl_leads: 0,
        lost_leads: 0,
        current_month_tasks: 0,
        previous_month_tasks: 0,
        total_refund: 0,
        total_refund_count: 0,
        cancellation_amount: 0,
        cancellation_count: 0
    }
    if(item) {
        return {
            first_row: [
                {
                    label: "Renewals | LLR",
                    icon: "icon-svg icon-renewal-sales",
                    value_1: `${item.renewal_leads} | ${item.llr_leads}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "LR Lost | Lost Leads",
                    icon: "icon-svg icon-renewal-sales",
                    value_1: `${item.lrl_leads} | ${item.lost_leads}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "Open Tasks in <br />this month",
                    icon: "icon-svg icon-this-month",
                    value_1: `${item.current_month_tasks}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "Open Tasks in <br />Previous months",
                    icon: "icon-svg icon-this-month",
                    value_1: `${item.previous_month_tasks}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
            ],
            second_row: [
                {
                    label: "Refund Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${item.total_refund}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "Total Refunds",
                    icon: "icon-svg icon-refund",
                    value_1: `${item.total_refund_count}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "Cancellation Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${item.cancellation_amount}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
                {
                    label: "Total Cancellations",
                    icon: "icon-svg icon-policies-cancelled",
                    value_1: `${item.cancellation_count}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 3, 
                },
            ]
        }
    }
}

function createTeamSummary(data) {
    if(Array.isArray(data)) {
        const value = data.map(x => {
            return {
                ...x,
                sales_target: currency(x.sales_target, 0),
                total_deals: currency(x.total_deals, 0),
                variance: currency(x.variance, 0),
                new_leads: currency(x.new_leads, 0),
                quick_leads: currency(x.quick_leads, 0),
                renewal_leads: currency(x.renewal_leads, 0),
                llr_leads: currency(x.llr_leads, 0),
                lrl_leads: currency(x.lrl_leads, 0),
                lost_leads: currency(x.lost_leads, 0),
                pending_leads: currency(x.pending_leads, 0),
                total_refund: currency(x.total_refund),
                cancellation_amount: currency(x.cancellation_amount),
                over_due_tasks: currency(x.over_due_tasks, 0),
                todays_task_count: currency(x.todays_task_count, 0),
                current_month_tasks: currency(x.current_month_tasks, 0),
                previous_month_tasks: currency(x.previous_month_tasks, 0),
                deals_cancellation_payable_amount: currency(x.deals_cancellation_payable_amount),
                total_revenue: currency(x.total_revenue),
                closing_ratio_total: x.closing_ratio_total + '%',
                closing_ratio_new: x.closing_ratio_new + '%',
                closing_ratio_renewal: x.closing_ratio_renewal + '%',
            }
        })
    
        value.push(teamSummaryTotal(data))
    
        return value
    }

    return []
}

function teamSummaryTotal(data) {
    if(data) {
        const total_deals = currency(calculateTotal(data, 'total_deals'), 0)
        const total_leads = currency(calculateTotal(data, 'total_leads'), 0)
        const new_deals = currency(calculateTotal(data, 'new_deals'), 0)
        const new_leads = currency(calculateTotal(data, 'new_leads'), 0)
        const renewal_deals = currency(calculateTotal(data, 'renewal_deals'), 0)
        const renewal_leads = currency(calculateTotal(data, 'renewal_leads'), 0)
        const closing_total = currency(roundUp((total_deals / total_leads) * 100))
        const closing_new = currency(roundUp((new_deals / new_leads) * 100))
        const closing_renewal = currency(roundUp((renewal_deals / renewal_leads) * 100))
        const quick_leads = currency(calculateTotal(data, 'quick_leads'), 0)
        return {
            agent_name: 'Total',
            total_leads,
            total_deals,
            new_leads,
            renewal_leads,
            quick_leads,
            variance: currency(roundUp(calculateTotal(data, 'variance')), 0),
            sales_target: currency(calculateTotal(data, 'sales_target'), 0),
            llr_leads: currency(calculateTotal(data, 'llr_leads'), 0),
            lrl_leads: currency(calculateTotal(data, 'lrl_leads'), 0),
            lost_leads: currency(calculateTotal(data, 'lost_leads'), 0),
            pending_leads: currency(calculateTotal(data, 'pending_leads'), 0),
            untouched_customers: currency(calculateTotal(data, 'untouched_customers'), 0),
            untouched_leads: currency(calculateTotal(data, 'untouched_leads'), 0),
            over_due_tasks: currency(calculateTotal(data, 'over_due_tasks'), 0),
            todays_task_count: currency(calculateTotal(data, 'todays_task_count'), 0),
            current_month_tasks: currency(calculateTotal(data, 'current_month_tasks'), 0),
            previous_month_tasks: currency(calculateTotal(data, 'previous_month_tasks'), 0),
            cancellation_amount: currency(calculateTotal(data, 'cancellation_amount')),
            new_deals,
            renewal_deals,
            total_refund: currency(calculateTotal(data, 'total_refund')),
            total_deals_cancelled: currency(calculateTotal(data, 'total_deals_cancelled')),
            deals_cancellation_payable_amount: currency(roundUp(calculateTotal(data, 'deals_cancellation_payable_amount'))),
            total_revenue: currency(roundUp(calculateTotal(data, 'total_revenue'))),
            closing_ratio_total: isFinite(closing_total) ? `${currency(roundUp(closing_total))}%` : `0.00%`,
            closing_ratio_new: isFinite(closing_new) ? `${currency(roundUp(closing_new))}%` : `0.00%`,
            closing_ratio_renewal: isFinite(closing_renewal) ? `${currency(roundUp(closing_renewal))}%` : `0.00%`
        }
    }

    return {}
}

function underwriterBox_1(data, user_id) {
    let item = <any>{}
    if(Array.isArray(data)) item = data.find(x => x.id == user_id)
    else item = data

    return [
        {
            label: "Policy Assigned",
            icon: "icon-svg icon-policy-assigned",
            value_1: `${currency(item.assigned, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Policy Completed",
            icon: "icon-svg icon-policy-completed",
            value_1: `${currency(item.completed, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Assigned Status in Previous Months",
            icon: "icon-svg icon-policy-assigned",
            value_1: `${currency(item.assigned_prev_months, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Provide Quotes",
            icon: "icon-svg icon-policy-assigned",
            value_1: `${currency(item.pending_quote_requests, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
    ]

}

function underwriterBox_2(data, user_id) {
    let item = <any>{}
    if(Array.isArray(data)) item = data.find(x => x.id == user_id)
    else item = data

    return [
        {
            label: "Cancellation Amount",
            icon: "icon-svg icon-revenue",
            value_1: `${currency(item.cancellation_amount)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Full Cancellation Amount",
            icon: "icon-svg icon-revenue",
            value_1: `${currency(item.full_cancellation_amount)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Partial Cancellation Amount",
            icon: "icon-svg icon-revenue",
            value_1: `${currency(item.partial_cancellation_amount)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
    ]

}

function underwriterBox_3(data, user_id) {
    let item = <any>{}
    if(Array.isArray(data)) item = data.find(x => x.id == user_id)
    else item = data

    return [
        {
            label: "Total Cancellations",
            icon: "icon-svg icon-policies-cancelled",
            value_1: `${currency(item.cancellation, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Full Cancellation Total",
            icon: "icon-svg icon-total-cancellation",
            value_1: `${currency(item.full_cancellation, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
        {
            label: "Partial Cancellation Total",
            icon: "icon-svg icon-partial-cancellation",
            value_1: `${currency(item.partial_cancellation, 0)}`,
            style_1: "#34bfa3 !important",
            class_1: "text-danger",
            top_class: "underwriter-dash-item pe-0",
            col: 3, 
        },
    ]

}

function accountBox(data) {
    return  [
        {
            title: 'Approval For',
            boxes: [
                {
                    label: "COD / Direct / Intransact Invoices",
                    icon: "icon-svg icon-invoices",
                    value_1: `${currency(data.invoices.cod_direct_intransact, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Manual Orders Invoices",
                    icon: "icon-svg icon-manual-order",
                    value_1: `${currency(data.invoices.manual_order, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Premium Discrepancy",
                    icon: "icon-svg icon-premium-descripancy",
                    value_1: `${currency(data.invoices.premium_discrepancy, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Online Invoices",
                    icon: "icon-svg icon-online-invoices",
                    value_1: `${currency(data.invoices.online, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-orange",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Cancellations",
                    icon: "icon-svg icon-cancelled",
                    value_1: `${currency(data.policies.cancellation_approval, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-red",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Refunds",
                    icon: "icon-svg icon-refund",
                    value_1: `${currency(data.refunds.pending, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
        {
            title: 'Policy Status',
            boxes: [
                {
                    label: "Policy Assigned",
                    icon: "icon-svg icon-policy-assigned",
                    value_1: `${currency(data.policies.assigned, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Policy Completed",
                    icon: "icon-svg icon-policy-completed",
                    value_1: `${currency(data.policies.completed, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
        {
            title: 'Deals Status',
            boxes: [
                {
                    label: "Total Deals",
                    icon: "icon-svg icon-total-sales",
                    value_1: `${currency(data.policies.total_deals, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Total Revenue",
                    icon: "icon-svg icon-total-revenue",
                    value_1: `${currency(data.policies.total_deals_revenue)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Total Cancelled",
                    icon: "icon-svg icon-policies-cancelled",
                    value_1: `${currency(data.policies.total_deals_cancelled, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Total Cancelled Amount",
                    icon: "icon-svg icon-total-revenue",
                    value_1: `${currency(data.policies.total_deals_cancelled_revenue)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-blue",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
        {
            title: 'Cancellation Refund Info',
            boxes: [
                {
                    label: "Total No. of Cancellations",
                    icon: "icon-svg icon-policies-cancelled",
                    value_1: `${currency(data.policies.cancelled, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-red",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Cancellations Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.policies.cancelled_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Policies Partially Cancelled",
                    icon: "icon-svg icon-partial-cancellation",
                    value_1: `${currency(data.policies.cancelled_partial, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-red",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Partial Cancellations Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.policies.cancelled_partial_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Policies Fully Cancelled",
                    icon: "icon-svg icon-total-cancellation",
                    value_1: `${currency(data.policies.cancelled_full, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-red",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Full Cancellations Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.policies.cancelled_full_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
        {
            title: '',
            boxes: [
                {
                    label: "Total No. of Refunds",
                    icon: "icon-svg icon-refund",
                    value_1: `${currency(data.refunds.completed, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Refunds Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.refunds.completed_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Partial Refunds",
                    icon: "icon-svg icon-partial-cancellation",
                    value_1: `${currency(data.refunds.completed_partial, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Partial Refunds Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.refunds.completed_partial_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Full Refunds",
                    icon: "icon-svg icon-total-cancellation",
                    value_1: `${currency(data.refunds.completed_full, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Full Refunds Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.refunds.completed_full_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
        {
            title: '',
            boxes: [
                {
                    label: "Refund Closed",
                    icon: "icon-svg icon-refund-closed",
                    value_1: `${currency(data.refunds.completed_closed, 0)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-purple",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
                {
                    label: "Refund Closed Amount",
                    icon: "icon-svg icon-revenue",
                    value_1: `${currency(data.refunds.completed_closed_amount)}`,
                    style_1: "#34bfa3 !important",
                    class_1: "dashboard-text-light-green",
                    top_class: "sales-dashboard-item pe-0",
                    col: 2, 
                },
            ]
        },
    ]
}

function marketingBox(data) {
    return [
        {
            label: "Leads By Source",
            icon: "icon-svg icon-total-sales",
            value_1: `${currency(getTotal(data.lead_source_leads.new, 'count'), 0)}`,
            style_1: "#f1416c !important",
            class_1: "title text-danger",
            value_2: `(${currency(getTotal(data.lead_source_leads.deleted, 'count'), 0)})`,
            style_2: "#f1416c !important",
            class_2: "fw-light fs-2",
            top_class: "marketing-dash-item",
            col: 6, 
        },
        {
            label: "Deals By Source",
            icon: "icon-svg icon-total-sales",
            value_1: `${currency(getTotal(data.lead_source_deals.new, 'count'), 0)}`,
            style_1: "#f1416c !important",
            class_1: "title text-danger",
            top_class: "marketing-dash-item",
            col: 6, 
        },
    ]
}

function getTotal(obj, column) {
    return obj[column].reduce((total,item) => {
        if(item != "") return total += parseInt(item)
        else return total += 0
    }, 0)
}