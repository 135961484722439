import { Actions } from "@/store/enums/StoreEnums"
import store from "@/store";

class Master {
   
    public static async getCountryCodes() {
        return await store.dispatch('GET_COUNTRY_CODES')
    }

    public static async getPolicyStartDateDays() {
        return await store.dispatch('GET_POLICY_START_DATE_DAYS_RESTRICTS')
    }

    public static async getCarYears({ is_vintage }) {
        return await store.dispatch('GET_YEARS', {is_vintage});
    }
}

export default Master