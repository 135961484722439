export function pageTitle(value) {
    switch(value) {
        case 'edit-quote': return 'Edit Lead Driver Details'
        case 'new-car-quote':
        case 'add-lead-driver-details': return 'Add Lead - Car & Driver Details'
        case 'add-driver-details': return 'Add New Driver Details'
        case 'edit-bike-quote': return 'Edit Bike'
        case 'customer-add-new-bike': return 'Add Lead - Bike Details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return 'Generate Quote PDF'
        case 'generate-invoice': return 'Generate New Invoice'
        case 'add-amend-invoice': return 'Amend Type'
        case 'report-policy': return 'Policies Assigned v/s Completed'
        case 'report-agent-sales': return 'Agent Sales'
        case 'admin-report': return 'Admin Report'
        case 'renewal-status': return 'Renewal Status'
        case 'report-approved-invoice': return 'Approved Invoices'
        case 'report-account-master': return 'Account Master'
        case 'report-discrepancy': return 'Discrepancy'
        case 'dashboard-details-report': return 'Dashboard Details'
        case 'report-lead-by-source': return 'Lead By Source'
        case 'report-skye-login-info': return 'Skye Login Info'
        case 'report-closed-leads': return 'Closed Leads'
        case 'report-deleted-leads': return 'Deleted Leads'
        case 'al-manarah-report': return 'Production Report'
        case 'lost-lead-report': return 'Lost Leads / LRL'
        case 'agent-escalations': return 'Agent Escalation'
        case 'underwriter-escalations': return 'Underwriter Escalation'
        case 'sales-tracking-report': return 'Sales Team Daily Update'
        default: return 'New User'
    }
}

export function setBreadCrumbs(value) {
    switch(value) {
        case 'edit-quote': return "Edit Lead Driver Details"
        case 'new-car-quote':
        case 'add-lead-driver-details': return "Add Lead - Car & Driver Details"
        case 'add-driver-details': return 'Add New Driver Details'
        case 'edit-bike-quote': return 'Edit Bike'
        case 'customer-add-new-bike': return 'Add Lead - Bike Details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return 'Generate Quote PDF'
        case 'generate-invoice': return 'Make Invoice'
        case 'customer-details': return 'Customer Details'
        case 'view-policy-details': return 'View Policy Details'
        case 'edit-policy-details': return 'Edit Policy Details'
        case 'customer-list': return 'Leads & Tasks'
        case 'policy-assigned': return 'Policy Assigned'
        case 'policy-completed': return 'Policy Completed'
        case 'policy-cancellation-request': return 'Policy Cancellation Request'
        case 'policy-cancelled': return 'Policies Cancelled'
        case 'policy-refund-closed': return 'Refund Closed'
        case 'add-amend-invoice': return 'Amend Invoice'
        case 'policy-cancellation-approval': return 'Approve & View Cancellations'
        case 'policy-refund-approval': return 'Approve & View Refunds'
        case 'update-data': return 'Update Data'
        case 'report-approved-invoice':
        case 'invoice-list': return 'Approved Invoices'
        case 'policy-approval': return 'Invoices For Approval'
        case 'declined-invoice': return 'Declined Invoices'
        case 'promotion-code-create': return 'Add Promotion Code'
        case 'promotion-code-edit': return 'Edit Promotion Code'
        case 'marketing-campaigns': return 'Marketing Campaigns'
        case 'marketing-spend': return 'Marketing Spend'
        case 'marketing-spend-tracking': return 'Marketing Spend'
        case 'marketing-report': return 'Marketing Report'
        case 'marketing-report-agent': return 'Marketing Report By Agent'
        case 'get-active-inactive-agents': return 'Marketing Report By Agent'
        case 'report-policy': return 'Policies Assigned v/s Completed'
        case 'report-agent-sales': return 'Agent Sales'
        case 'admin-report': return 'Admin Report'
        case 'renewal-status': return 'Renewal Status'
        case 'report-account-master': return 'Account Master'
        case 'report-discrepancy': return 'Discrepancy'
        case 'report-lead-by-source': return 'Lead By Source'
        case 'dashboard-details-report': return 'Dashboard Details'
        case 'report-skye-login-info': return 'Skye Login Info'
        case 'report-closed-leads': return 'Closed Leads'
        case 'report-deleted-leads': return 'Deleted Leads'
        case 'al-manarah-report': return 'Production Report'
        case 'lost-lead-report': return 'Lost Leads / LRL'
        case 'agent-escalations': return 'Agent Escalation'
        case 'underwriter-escalations': return 'Underwriter Escalation'
        case 'sales-tracking-report': return 'Sales Team Daily Update'
        default: return "Add User Details"
    }
}

export function setFromBreadCrumbs(value) {
    switch(value) {
        case 'edit-quote':
        case 'new-car-quote': 
        case 'add-lead-driver-details': 
        case 'edit-bike-quote':
        case 'customer-add-new-bike':
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote':
        case 'generate-invoice':
        case 'customer-details':
        case 'add-driver-details': return ["Leads & Tasks"]
        case 'view-policy-details':
        case 'policy-assigned':
        case 'policy-completed':
        case 'policy-cancellation-request':
        case 'policy-cancelled':
        case 'policy-refund-closed':
        case 'policy-cancellation-approval':
        case 'policy-refund-approval':
        case 'update-data':
        case 'edit-policy-details': return ['Policy']
        case 'invoice-list':
        case 'policy-approval':
        case 'declined-invoice':
        case 'add-amend-invoice': return ['Invoice']
        case 'promotion-code-create':
        case 'promotion-code-edit': return ['Promotion Code']
        case 'marketing-campaigns':
        case 'marketing-spend':
        case 'marketing-spend-tracking':
        case 'marketing-report':
        case 'marketing-report-agent':
        case 'get-active-inactive-agents':
        case 'customer-list': return []
        case 'report-agent-sales':
        case 'admin-report':
        case 'report-approved-invoice':
        case 'report-account-master':
        case 'report-discrepancy':
        case 'dashboard-details-report':
        case 'report-lead-by-source':
        case 'report-skye-login-info':
        case 'report-closed-leads':
        case 'report-deleted-leads':
        case 'al-manarah-report':
        case 'renewal-status':
        case 'lost-lead-report': 
        case 'agent-escalations':
        case 'underwriter-escalations':
        case 'sales-tracking-report':
        case 'report-policy': return ['Reports']
        default: return ["Leads & Tasks"]
    }
}