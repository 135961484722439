
import { computed, defineComponent, ref, onMounted } from "vue";
import InvoiceService from "@/core/services/car/InvoiceService";
import { useStore } from "vuex";

export default defineComponent({
  setup(props, context) {
    const store = useStore();

    const onlinePaymentModal = computed(() => {
      return store.getters.getOnlinePaymentInfoModal;
    });

    const data = computed(() => {
      return store.getters.getOnlinePaymentInfo;
    });

    onMounted(() => {
      // PolicyService.resetPolicyError();
    });

    const close = () => {
      InvoiceService.setOnlinePaymentInfoModal(false);
    };

    const currency = (e) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "AED"
      });

      return formatter.format(e);
    };

    const roundValue = (value) => {
      return value.toFixed(2);
    }

    const totalAmount = computed(() => {
      return parseFloat(data.value.invoice.sale_price) + parseFloat(data.value.invoice.admin_extra_charges)
    })

    const premium = computed(() => {
      return parseFloat(data.value.invoice.original_price) - parseFloat(data.value.invoice.original_price_vat) - parseFloat(data.value.invoice.totalAddons)
    })

    const promoCode = computed(()=>{
      return data.value.invoice.promo_code?.promotion_code;
    })

    const showPolicyPurchaseDate = computed(()=>{
      return (data.value.invoice.policy_purchase && data.value.invoice.policy_purchase.policy_start_date);
    })

    const isBike = computed(()=>{
      return (data.value.invoice && data.value.invoice.car_lead && data.value.invoice.car_lead.vehicle_type == 2)
    });

    const showDetailsSection = computed(()=>{
      return (data.value.policy && (data.value.policy.car_policy_highlight_title || data.value.policy.roadside_assistance || (data.value.addOns && data.value.addOns.length > 0)));
    })

    const showHighlights = computed(()=>{
      return data.value.policy && data.value.policy.car_policy_highlight_title;
    })

    const showRoadSideAssistance = computed(()=>{
      return data.value.policy && data.value.policy.roadside_assistance;
    })

    const showAddons = computed(()=>{
      return data.value.addOns && data.value.addOns.length > 0;
    })

    const showSpecialFeatures = computed(()=>{
      return data.value && data.value.policy.special_features;
    })

    return {
      totalAmount,
      premium,
      isBike,
      showDetailsSection,
      currency,
      roundValue,
      onlinePaymentModal,
      data,
      close,
      promoCode,
      showPolicyPurchaseDate,
      showHighlights,
      showRoadSideAssistance,
      showAddons,
      showSpecialFeatures
    };
  }
});
