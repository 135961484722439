
import { defineComponent, onMounted } from 'vue'
import socket from '@/main-socket'

export default defineComponent({
    props: {
        channel: {
            type: String,
            required: true
        },
        listen: {
            type: String,
            required: false,
            default: 'PopupNotification'
        }
    },
    setup(_, { emit }) {

        const listenSocket = async() => {
            socket.channel(_.channel)
            .listen(_.listen, async(response:any) => {
                emit('action', response);
            })
        }

        onMounted( async() => {
            await listenSocket()

            // document.addEventListener('visibilitychange', function (event) {
            //     const isSwitchApp = _.channel.startsWith('switchapp-');
            //     if (document.hidden && (!isSwitchApp)) {
            //         socket.leave(_.channel)
            //     } else {
            //         listenSocket()
            //     }
            // });
        })
    },
})
