const carDetails = [
    {
        title: 'Car Year',
        prop: 'car_year',
        id: 'customer-car-year',
        option: 1
    },
    {
        title: 'Car Make',
        prop: 'manufacturer_id',
        id: 'customer-manufacturer-id',
        option: 2
    },
    {
        title: 'Car Model',
        prop: 'model_id',
        id: 'customer-model-id',
        option: 3
    },
    {
        title: 'Trim Level',
        prop: 'trim_level_id',
        id: 'customer-trim-level-id',
        option: 4
    }
]

const bikeDetails = [
    {
        title: 'Year',
        prop: 'car_year',
        id: 'customer-car-year',
        option: 1
    },
    {
        title: 'Engine Capacity',
        prop: 'trim_level_id',
        id: 'customer-trim-level-id',
        option: 4
    }
]

const emirates = [
    { value: 0, label: "Abu Dhabi" },
    { value: 1, label: "Ajman" },
    { value: 2, label: "Dubai" },
    { value: 3, label: "Fujairah" },
    { value: 4, label: "Ras Al Khaimah" },
    { value: 5, label: "Sharjah" },
    { value: 6, label: "Umm Al Quwain" }
]

const yesNo = [
    {
        value: 1,
        label: 'Yes'
    },
    {
        value: 2,
        label: 'No'
    },
]

const personalCommercial = [
    {
        value: 1,
        label: 'Personal'
    },
    {
        value: 2,
        label: 'Commercial'
    },
]

const drivingExperience = [
    {
        value: 1,
        label: '0 - 6 months'
    },
    {
        value: 2,
        label: '6 - 12 months'
    },
    {
        value: 3,
        label: '1 - 2 Years'
    },
    {
        value: 4,
        label: 'More than 2 years'
    }
]

const ncc = [
    { value: 1, label: 'No - I have no certificate' },
    { value: 2, label: 'Yes - 1 year with proof' },
    { value: 3, label: 'Yes - 2 years with proof' },
    { value: 4, label: 'Yes - 3 or more years with proof' }
]

const genders = [
    {
        value: 1,
        label: 'Male'
    },
    {
        value: 2,
        label: 'Female'
    },
    {
        value: 3,
        label: 'Rather not Say'
    },
]

const policy_types = [
    {
        value: 0,
        label: 'Select Policy Type'
    },
    {
        value: 1,
        label: 'Comprehensive'
    },
    {
        value: 5,
        label: 'Third Party'
    },
]

const repairs = [
    {
        value: 1,
        label: 'Non Agency'
    },
    {
        value: 2,
        label: 'Agency'
    },
]

const amend_types = [
    {
        label: 'Premium',
        value: 1
    },
    {
        label: 'Extension',
        value: 2
    },
    {
        label: 'Name Transfer',
        value: 3
    },
    {
        label: 'Orange Card',
        value: 4
    },
    {
        label: 'General Endorsement',
        value: 5
    },
]

const customerInformationProp = ['name', 'phone_number', 'email']
const carDetailsProp = ['car_year', 'manufacturer_id', 'model_id', 'trim_level_id', 'car_value', 'is_new', 'registration_emirate', 'first_registration_date', 'current_policy_active', 'policy_start_date', 'gcc_specification', 'personal_use', 'third_party_liability']
const driverDetaisProp = ['dob', 'nationality', 'first_driving_license_country', 'driving_experience', 'uae_driving_experience', 'claimed_insurance', 'claims', 'no_claim_certificate', 'gender', 'driver_name']
const salesAgentDetailsProp = ['agent']
const policyDetailsProp = ['policy_type', 'companies']
const invoiceDetailsProp = ['bai_car_insurance_policy_id', 'is_agency_repair', 'original_price', 'sale_price', 'is_discount_given', 'discount_amount', 'payment_type', 'order_description', 'reference_no', 'expiry_date_time', 'intransact_policy_id', 'language', 'payment_date', 'invoice_doc']

export {
    carDetails,
    emirates,
    yesNo,
    personalCommercial,
    drivingExperience,
    ncc,
    genders,
    customerInformationProp,
    carDetailsProp,
    driverDetaisProp,
    salesAgentDetailsProp,
    policyDetailsProp,
    invoiceDetailsProp,
    bikeDetails,
    policy_types,
    repairs,
    amend_types
}