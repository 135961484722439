
import { defineComponent, onMounted, ref, watchEffect } from 'vue'

export default defineComponent({
    name: 'custom-amount',
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        cancellationAmount: {
            type: Boolean,
            required: false,
            default: false
        },
        resetAmount: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    

    setup(_, context) {  
        
        let currentAmount = ref<any>('0');

        onMounted(()=>{
            currentAmount.value = _.modelValue;
            console.log('currentAmount',currentAmount.value)
        })

        watchEffect(() => {
            if(_.disabled){
                currentAmount.value = _.modelValue
            }

            if(typeof _.modelValue === 'string') {
                currentAmount.value = parseFloat(_.modelValue)
            }

            if(typeof _.modelValue === 'number' && _.cancellationAmount) {
                currentAmount.value = parseFloat(_.modelValue)
            }

            if(parseFloat(_.modelValue) === 0 && _.resetAmount) {
                currentAmount.value = 0
            }
        })

        const updateValue = (e) => {
            // const val = parseFloat(currentAmount.value.toString().replace(' ', '').replace(',', ''))
            // currentAmount.value = roundUp(val);
            // if(isNaN(currentAmount.value)) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0)    
            // }
            
            // if(!currentAmount.value) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0) 
            // }
            
            // if(isNaN(currentAmount.value)) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0)    
            // }
            // currentAmount.value = e.target.value
            context.emit("update:modelValue", currentAmount.value)
            context.emit('input', e.target.value)
        }

        const correctValue = (e) => {
            const val = parseFloat(currentAmount.value.toString().replace(' ', '').replace(',', ''))
            currentAmount.value = roundUp(val);
            if(isNaN(currentAmount.value)) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0)    
            }
            
            if(!currentAmount.value) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0) 
            }
            
            // if(isNaN(currentAmount.value)) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0)    
            // }
            
            context.emit('vc-change', e)
            context.emit("update:modelValue", currentAmount.value)
        }

        const updateDecimal = () => {
            const val = parseFloat(currentAmount.value.toString().replace(',', ''))
            if(isNaN(currentAmount.value)) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0)    
            }
            if(currentAmount.value > 9999999999.99) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0) 
            }
            
            currentAmount.value = roundUp(val);
            context.emit("update:modelValue", val)
        }

        const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

        const focus = ref()

        const isFocus = () => {
            focus.value.focus()
        }

        return {
            correctValue,
            isFocus,
            focus,
            roundUp,
            updateValue,
            updateDecimal,
            currentAmount
        }
    },
})
