import store from "@/store";

class Policy {

    public static async getPolicyDetails(payload) {
        return await store.dispatch('GET_POLICY', payload)
    }

    public static async uploadAmendDocument(payload) {
        return await store.dispatch('UPLOAD_AMEND_DOCUMENTS', payload)
    }
}

export default Policy