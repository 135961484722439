import store from "@/store";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { App, computed } from "vue";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { notif } from '@/store/stateless/store';

// import $ from 'jquery'; 
/**
 * @description service to call HTTP request via Axios
 */
class InvoiceService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    InvoiceService.vueInstance = app;
  }

  public static getInsurancePolicyList(payload = {}) {// 
      return new Promise<void>((res, rej) => {
        store
        .dispatch(Actions.GET_INSURANCE_POLICIES, payload)
        .then((data) => {
          res(data);
        })
        .catch((error) => { 
          console.log(error);  
          rej(error); 
        })
       
    })
  }
  public static getInsurancePolicyListWithFilter(payload) {//
    store
      .dispatch(Actions.GET_INSURANCE_POLICIES, payload)
      .then((data) => { console.log(data); })
      .catch((error) => { console.log(error); })
  }
  public static addInvoice(payload) {// 
    return new Promise<void>((res, rej) => {
        store
        .dispatch(Actions.ADD_INVOICE, payload)
        .then((data) => {
          // router.push({ name: "policy-approval" });
          res(data);
        })
        .catch((error) => { 
          console.log(error);  
          rej(error); 
        })
       
    })
  }
  public static runInvoiceScheduler() {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.RUN_INVOICE_SCHEDULER)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }
  public static getInvoiceList(payload, index = 1) {
    payload.index = index;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INVOICE_LIST, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });

  }
  public static getInvoiceListCount(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INVOICE_LIST_COUNT, payload)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }
  public static getInvoiceListOld(payload, index = 1) {
    payload.index = index;
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INVOICE_LIST_OLD, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });

  }

  public static fetchInvoiceList(payload, index = 1) {
    payload.index = index;
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.FETCH_INVOICE_LIST, payload)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });

  }

  public static addInvoiceDocument(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.ADD_INVOICE_DOCUMENTS, payload)
        .then((data) => {
          // console.log('1');
          // store.commit(Mutations.SET_OPEN_UPLOAD_INVOICE)
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static getInvoiceDocuments(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.GET_INVOICE_DOCUMENTS, payload)
        .then((data) => {
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static updateInvoice(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.UPDATE_INVOICE, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });
  }
  public static exportTransactionApprovals(payload) {
    return new Promise<void>((resolve, reject) => {
      store
        .dispatch(Actions.EXPORT_TRANSACTION_APPROVALS, payload)
        .then((data) => {
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }
  public static getAmendInvoiceInformation(payload){
    return new Promise((resolve, reject) => {
      store
        .dispatch(Actions.GET_AMEND_INVOICE_INFORMATION, payload)
        .then((data) => {
          
          console.log('1');
          resolve(data);

        })
        .catch((error) => {
          console.log(error);
          reject(error);
        })
    });
  }

  public static getOnlinePaymentInfo(payload){
    return new Promise((resolve, reject) => {
      store
        .dispatch(Actions.GET_ONLINE_PAYMENT_INFO, payload)
        .then((data) => {
          
          resolve(data);

        })
        .catch((error) => {
          reject(error);
        })
    });
  }

  public static getApprovalInvoiceListCount(payload) {
    return new Promise<any>((resolve, reject) => {
      store
        .dispatch(Actions.GET_APPROVAL_INVOICE_LIST_COUNT, payload)
        .then((data) => {
          // resolve(data);

        })
        .catch((error) => {
          // reject(error);
        })
    });

  }
  public static closeModal() {
    store.commit(Mutations.SET_OPEN_DOCUMENTS);
  }
  public static setModalInvoice(payload) {
    store.commit(Mutations.SET_OPEN_UPLOAD_INVOICE, payload);
  }
  public static setInvoiceId(payload) {
    store.commit(Mutations.SET_INVOICE_ID, payload);
  }

  public static clearUploadInvoiceError(payload) {
    store.commit(Mutations.SET_UPLOAD_RECEIPT_ERROR, payload)
  }

  public static async getChildInvoice(payload) {
      return await store.dispatch(Actions.GET_CHILD_INVOICES, payload);
  }

  public static async addAmendInvoice(payload){

    store.commit(Mutations.SET_DISABLE_BUTTON, true)
    return await store.dispatch(Actions.ADD_AMEND_INVOICE, payload)
    
    // return new Promise((resolve, reject) => {


    //   store.commit(Mutations.SET_DISABLE_BUTTON, true)
    //   store
    //     .dispatch(Actions.ADD_AMEND_INVOICE, payload)
    //     .then((data) => {
          
    //       // Swal.fire({
    //       //   text: data.message,
    //       //   icon: "success",
    //       //   buttonsStyling: false,
    //       //   confirmButtonText: "Okay!",
    //       //   customClass: {
    //       //     confirmButton: "btn fw-bold btn-light-success",
    //       //   },
    //       // });
    //       store.commit(Mutations.SET_DISABLE_BUTTON, false)
    //       // debugger
    //       // router.push({ name: "policy-approval" });
    //       // notif.simple('Invoice Amend', 'success', data.message);
    //       resolve(data)
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       // store.commit(Mutations.SET_DISABLE_BUTTON, false)
    //       reject(error);
    //     })
    // });
  }

  public static setOnlinePaymentInfoModal(payload) {
    store.commit(Mutations.SET_ONLINE_PAYMENT_INFO_MODAL, payload);
  }

  public static clearIsEmailError() {
    store.commit(Mutations.SET_EMAIL_EXIST_ERROR, false)
  }

  public static initiateAmendRefund(payload) {
    store.dispatch(Actions.INITIATE_AMEND_REFUND, payload);
  }

  public static async initiateGE(payload) {
    return await store.dispatch(Actions.INITIATE_REFUND, payload);
  }
  
}
export default InvoiceService;